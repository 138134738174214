import { handleActions } from 'redux-actions';
import {
  getInscricaoRequest,
  getInscricaoSuccess,
  getInscricaoError,
  getInscricoesStatsRequest,
  getInscricoesStatsSuccess,
  getInscricoesStatsError,
  fetchInscricoesRequest,
  fetchInscricoesSuccess,
  fetchInscricoesError,
  fetchInscricoesByAlunoRequest,
  fetchInscricoesByAlunoSuccess,
  fetchInscricoesByAlunoError,
  fetchPdfCertificadoRequest,
  fetchPdfCertificadoSuccess,
  fetchPdfCertificadoError,
  fetchSearchInscricoesRequest,
  fetchSearchInscricoesSuccess,
} from '../actions/inscricoesActions';

const defaultState = {
  inscricao: {},
  inscricoes: [],
  pdfCertificado: {},
  totalPages: 0,
  count: 0,
  isFetching: false,
  isProcessing: false,
  error: null,
  stats: [],
};

export default handleActions(
  {
    [getInscricaoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getInscricaoSuccess](state, { payload }) {
      return {
        ...state,
        inscricao: payload,
        isFetching: false,
        error: null,
      };
    },
    [getInscricaoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getInscricoesStatsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getInscricoesStatsSuccess](state, { payload }) {
      return {
        ...state,
        stats: payload,
        isFetching: false,
        error: null,
      };
    },
    [getInscricoesStatsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchInscricoesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchInscricoesSuccess](state, { payload }) {
      return {
        ...state,
        inscricoes: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchInscricoesError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchInscricoesByAlunoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchInscricoesByAlunoSuccess](state, { payload }) {
      return {
        ...state,
        inscricoes: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchInscricoesByAlunoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchPdfCertificadoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPdfCertificadoSuccess](state, { payload }) {
      return {
        ...state,
        pdfCertificado: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchPdfCertificadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchInscricoesRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchInscricoesSuccess](state, { payload }) {
      return {
        ...state,
        inscricoes: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
