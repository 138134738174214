import React, { useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
} from 'reactstrap';
import classnames from 'classnames';
import AlunoForm from './components/AlunoForm';
import InscricoesAlunoList from './components/InscricoesAlunoList';
import PagamentosAlunoList from './components/PagamentosAlunoList';

const Aluno = (props) => {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Aluno</h3>
          <h3 className="page-subhead subhead">
            Adicione um novo aluno ou altere suas informações cadastrais
          </h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} xl={12}>
          <Card>
            <CardBody>
              <div className="tabs tabs--vertical tabs--vertical-colored">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <h4 className="bold-text subhead">ALUNO</h4>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => toggle('1')}
                      >
                        Cadastro
                      </NavLink>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                      >
                        Inscrições
                      </NavLink>{' '}
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggle('3')}
                      >
                        Pagamentos
                      </NavLink>{' '}
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <AlunoForm isHorizontal isAboveError props={props} />
                    </TabPane>
                    <TabPane tabId="2">
                      <InscricoesAlunoList props={props} />
                    </TabPane>
                    <TabPane tabId="3">
                      <PagamentosAlunoList props={props} />
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Aluno;
