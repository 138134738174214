import { createAction } from 'redux-actions';
import pagamentosApi from '../../utils/api/pagamentosApi';
import { displayError, displaySuccess } from './notificacoesActions';

export const getPagamentoRequest = createAction('GET_PAGAMENTO_REQUEST');
export const getPagamentoSuccess = createAction('GET_PAGAMENTO_SUCCESS');
export const getPagamentoError = createAction('GET_PAGAMENTO_ERROR');

export const fetchPagamentosRequest = createAction('FETCH_PAGAMENTOS_REQUEST');
export const fetchPagamentosSuccess = createAction('FETCH_PAGAMENTOS_SUCCESS');
export const fetchPagamentosError = createAction('FETCH_PAGAMENTOS_ERROR');

export const fetchPagamentosByAlunoRequest = createAction(
  'FETCH_PAGAMENTOS_BY_ALUNO_REQUEST'
);
export const fetchPagamentosByAlunoSuccess = createAction(
  'FETCH_PAGAMENTOS_BY_ALUNO_SUCCESS'
);
export const fetchPagamentosByAlunoError = createAction(
  'FETCH_PAGAMENTOS_BY_ALUNO_ERROR'
);

export const fetchSearchPagamentosRequest = createAction(
  'FETCH_SEARCH_PAGAMENTOS_REQUEST'
);
export const fetchSearchPagamentosSuccess = createAction(
  'FETCH_SEARCH_PAGAMENTOS_SUCCESS'
);

export const getResumoDiarioRequest = createAction('GET_RESUMO_DIARIO_REQUEST');
export const getResumoDiarioSuccess = createAction('GET_RESUMO_DIARIO_SUCCESS');
export const getResumoDiarioError = createAction('GET_RESUMO_DIARIO_ERROR');

export const getPagamento = (id) => async (dispatch) => {
  try {
    dispatch(getPagamentoRequest());
    const { data } = await pagamentosApi.getPagamento(id);
    dispatch(getPagamentoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getPagamentoError(e));
    dispatch(displayError(e));
  }
};

export const fetchPagamentos = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchPagamentosRequest());
    const { data } = await pagamentosApi.getPagamentos(page, pageSize);
    dispatch(fetchPagamentosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchPagamentosError(e));
    dispatch(displayError(e));
  }
};

export const fetchPagamentosByAluno = (id) => async (dispatch) => {
  try {
    dispatch(fetchPagamentosByAlunoRequest());
    const { data } = await pagamentosApi.getPagamentosByAluno(id);
    dispatch(fetchPagamentosByAlunoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchPagamentosByAlunoError(e));
    dispatch(displayError(e));
  }
};

export const getResumoDiario = (dataInicio, dataFim) => async (dispatch) => {
  try {
    dispatch(getResumoDiarioRequest());
    const { data } = await pagamentosApi.getResumoDiario(dataInicio, dataFim);
    dispatch(getResumoDiarioSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getResumoDiarioError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchPagamentos =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchPagamentosRequest());
      const { data } = await pagamentosApi.searchPagamentos(
        page,
        pageSize,
        search
      );
      dispatch(fetchSearchPagamentosSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
    }
  };
