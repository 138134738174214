import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonToolbar, Card, CardBody, Col, Spinner } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import { Link } from 'react-router-dom';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchCupons } from '../../../redux/actions/cuponsActions';
import { BadgeCupom } from '../../../shared/components/Badges';

const CuponsList = () => {
  const dispatch = useDispatch();
  const cupons = useSelector((state) => state.cupons.cupons);
  const totalPages = useSelector((state) => state.cupons.totalPages);
  const totalItems = useSelector((state) => state.cupons.totalItems);
  const isFetching = useSelector((state) => state.cupons.isFetching);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const colunas = useMemo(
    () => [
      {
        Header: 'CÓDIGO',
        accessor: 'codigo',
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'TIPO',
        accessor: 'tipo_desconto',
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'VALOR',
        accessor: 'valor_desconto',
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: 'APLICAÇÕES',
        accessor: 'quantidade_aplicavel',
        disableGlobalFilter: true,
        width: 50,
      },
      {
        Header: 'VALIDADE',
        accessor: 'valido_ate',
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'AÇÃO',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 65,
      },
    ],
    []
  );

  const dadosTabela = (cupons) => {
    return cupons.map((cupom) => ({
      codigo: cupom.codigo,
      quantidade_aplicavel: cupom.quantidade_aplicavel,
      valor_desconto:
        cupom.tipo_desconto == 'valor'
          ? `R$${cupom.valor_desconto}`
          : `${cupom.valor_desconto}%`,
      tipo_desconto: <BadgeCupom tipo={cupom.tipo_desconto} />,
      valido_ate: !cupom.valido_ate
        ? 'Indefinido'
        : moment(cupom.valido_ate).format('DD/MM/YYYY'),
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/cupom/edit/${cupom._id}`}
        >
          <EditIcon /> Editar
        </Link>
      ),
    }));
  };

  // fetch certificados when page load
  useEffect(() => {
    dispatch(fetchCupons(parseInt(page), pageSize));
  }, [dispatch, page, pageSize]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  return (
    <Col md={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Cupons cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link className="btn btn-primary list__btn-add" to="/cupom/novo">
                <PlusIcon /> Novo Cupom
              </Link>
            </ButtonToolbar>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {cupons && !isFetching && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(cupons)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CuponsList;
