import { createAction } from 'redux-actions';
import certificadosApi from '../../utils/api/certificadosApi';
import { displayError, displaySuccess } from './notificacoesActions';

export const fetchCertificadosRequest = createAction(
  'FETCH_CERTIFICADO_REQUEST'
);
export const fetchCertificadosSuccess = createAction(
  'FETCH_CERTIFICADO_SUCCESS'
);
export const fetchCertificadosError = createAction('FETCH_CERTIFICADO_ERROR');

export const getCertificadoRequest = createAction('GET_CERTIFICADO_REQUEST');
export const getCertificadoSuccess = createAction('GET_CERTIFICADO_SUCCESS');
export const getCertificadoError = createAction('GET_CERTIFICADO_ERROR');

export const createCertificadoRequest = createAction(
  'CREATE_CERTIFICADO_REQUEST'
);
export const createCertificadoSuccess = createAction(
  'CREATE_CERTIFICADO_SUCCESS'
);
export const createCertificadoError = createAction('CREATE_CERTIFICADO_ERROR');

export const updateCertificadoRequest = createAction(
  'UPDATE_CERTIFICADO_REQUEST'
);
export const updateCertificadoSuccess = createAction(
  'UPDATE_CERTIFICADO_SUCCESS'
);
export const updateCertificadoError = createAction('UPDATE_CERTIFICADO_ERROR');

export const newCertificadoRequest = createAction('NEW_CERTIFICADO_REQUEST');

export const fetchCertificadosStatsRequest = createAction(
  'FETCH_CERTIFICADOS_STATS_REQUEST'
);
export const fetchCertificadosStatsSuccess = createAction(
  'FETCH_CERTIFICADOS_STATS_SUCCESS'
);
export const fetchCertificadosStatsError = createAction(
  'FETCH_CERTIFICADOS_STATS_ERROR'
);

export const fetchCertificados = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchCertificadosRequest());
    const { data } = await certificadosApi.getCertificados(page, pageSize);
    dispatch(fetchCertificadosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchCertificadosError());
  }
};

export const createCertificado = (form) => async (dispatch) => {
  try {
    dispatch(createCertificadoRequest());
    const { data } = await certificadosApi.createCertificado(form);
    dispatch(createCertificadoSuccess(data));
    dispatch(displaySuccess('Certificado incluído com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(createCertificadoError(e));
    dispatch(displayError(e));
  }
};

export const getCertificado = (id) => async (dispatch) => {
  try {
    dispatch(getCertificadoRequest());
    const { data } = await certificadosApi.getCertificado(id);
    dispatch(getCertificadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getCertificadoError(e));
    dispatch(displayError(e));
  }
};

export const updateCertificado = (id, form) => async (dispatch) => {
  try {
    dispatch(updateCertificadoRequest());
    const { data } = await certificadosApi.updateCertificado(id, form);
    dispatch(updateCertificadoSuccess(data));
    dispatch(displaySuccess('Certificado atualizado com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(updateCertificadoError(e));
    dispatch(displayError(e));
  }
};

export const newCertificado = () => async (dispatch) => {
  dispatch(newCertificadoRequest());
};

export const fetchCertificadosStats = () => async (dispatch) => {
  try {
    dispatch(fetchCertificadosStatsRequest());
    const { data } = await certificadosApi.getCertificadosStats();
    dispatch(fetchCertificadosStatsSuccess(data));
  } catch (e) {
    dispatch(fetchCertificadosStatsError());
    dispatch(displayError(e));
  }
};
