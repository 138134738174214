import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonToolbar, Card, CardBody, Col, Spinner } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import { Link } from 'react-router-dom';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchCertificados } from '../../../redux/actions/certificadosActions';
import { BadgeCertificado } from '../../../shared/components/Badges';

const CertificadosList = () => {
  const dispatch = useDispatch();
  const certificados = useSelector((state) => state.certificados.certificados);
  const totalPages = useSelector((state) => state.certificados.totalPages);
  const totalItems = useSelector((state) => state.certificados.totalItems);
  const isFetching = useSelector((state) => state.certificados.isFetching);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const colunas = useMemo(
    () => [
      {
        Header: 'TITULO',
        accessor: 'titulo',
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'PROFESSOR',
        accessor: 'professor_name',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'CARGA HORÁRIA',
        accessor: 'carga_horaria',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'VALOR',
        accessor: 'valor',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'AÇÃO',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 65,
      },
    ],
    []
  );

  const dadosTabela = (certificados) => {
    return certificados.map((certificado) => ({
      titulo: certificado.titulo,
      professor_name: certificado.professor?.name,
      carga_horaria: `${certificado.carga_horaria}hs`,
      valor: `R$${certificado.valor}`,
      status: <BadgeCertificado status={certificado.status} />,
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/certificado/edit/${certificado._id}`}
        >
          <EditIcon /> Editar
        </Link>
      ),
    }));
  };

  // fetch certificados when page load
  useEffect(() => {
    dispatch(fetchCertificados(parseInt(page), pageSize));
  }, [dispatch, page, pageSize]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Certificados cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link
                className="btn btn-primary list__btn-add"
                to="/certificado/novo"
              >
                <PlusIcon /> Novo certificado
              </Link>
            </ButtonToolbar>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {certificados && !isFetching && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(certificados)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CertificadosList;
