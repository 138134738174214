import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PagamentosList from './components/PagamentosList';

const Pagamentos = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Pagamentos</h3>
        <h3 className="page-subhead subhead">
          Exibindo todos os pagamentos feitas na plataforma, mostrando as mais
          recentes primeiro
        </h3>
      </Col>
    </Row>
    <Row>
      <PagamentosList />
    </Row>
  </Container>
);

export default Pagamentos;
