import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Progress,
  Spinner,
  Table,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { getPagamento } from '../../../redux/actions/pagamentosActions';
import { Link } from 'react-router-dom';
import { BadgePagamento } from '../../../shared/components/Badges';

const imagensPagamento = {
  cupom: `${process.env.PUBLIC_URL}/img/cards/cupom.svg`,
  cartaocredito: `${process.env.PUBLIC_URL}/img/cards/cartao.svg`,
  boletobancario: `${process.env.PUBLIC_URL}/img/cards/boleto.svg`,
  pix: `${process.env.PUBLIC_URL}/img/cards/pix.svg`,
};

const DetalhePagamento = ({ props }) => {
  const dispatch = useDispatch();
  const pagamento = useSelector((state) => state.pagamentos.pagamento);
  const isFetching = useSelector((state) => state.pagamentos.isFetching);
  const { id } = props.match.params;
  moment.locale('pt-br');

  useEffect(() => {
    dispatch(getPagamento(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      {isFetching && <Spinner className="loading-spinner" />}
      {pagamento && !isFetching && (
        <Fragment>
          <Col md={12} lg={5}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">#Id: {pagamento._id}</h5>
                </div>
                <table className="project-summary__info">
                  <tbody>
                    <tr>
                      <th>Status:</th>
                      <td>
                        <BadgePagamento status={pagamento.status} />
                      </td>
                    </tr>
                    <tr>
                      <th>Data:</th>
                      <td>{moment(pagamento.createdAt).format('ll LT')}</td>
                    </tr>
                    <tr>
                      <th>Aluno:</th>
                      <td>{pagamento.aluno?.name}</td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>{pagamento.aluno?.email}</td>
                    </tr>
                    <tr>
                      <th>Moeda:</th>
                      <td>{pagamento.moeda}</td>
                    </tr>
                    <tr>
                      <th>Forma de pagamento:</th>
                      <td>{pagamento.forma_pagamento}</td>
                    </tr>
                    <tr>
                      <th>Gateway:</th>
                      <td>{pagamento.gateway}</td>
                    </tr>
                    <tr>
                      <th>Valor:</th>
                      <td>R${pagamento.valor}</td>
                    </tr>
                    <tr>
                      <th>Cupom:</th>
                      <td>{`${pagamento.cupom?.codigo} (${
                        pagamento.cupom?.tipo_desconto === 'valor' ? 'R$' : ''
                      }${pagamento.cupom?.valor_desconto}${
                        pagamento.cupom?.tipo_desconto === 'percentual'
                          ? '%'
                          : ''
                      })`}</td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={7}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">LOG</h5>
                </div>
                <div>
                  <pre>{JSON.stringify(pagamento.log, null, 2)}</pre>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetalhePagamento;
