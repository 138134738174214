/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'O campo Nome não pode ser vazio';
  }
  if (!values.email) {
    errors.email = 'O campo Email não pode ser vazio';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Email inválido';
  }
  if (!values.id_uscreen) {
    errors.id_uscreen = 'O campo ID Uscreen não pode ser vazio';
  }
  if (!values.statusSelect) {
    errors.statusSelect = 'Selecione uma opção, por favor';
  }

  return errors;
};

export default validate;
