import { createAction } from 'redux-actions';
import usuariosApi from '../../utils/api/usuariosApi';
import { displayError, displaySuccess } from './notificacoesActions';

export const fetchUsuariosRequest = createAction('FETCH_USUARIOS_REQUEST');
export const fetchUsuariosSuccess = createAction('FETCH_USUARIOS_SUCCESS');
export const fetchUsuariosError = createAction('FETCH_USUARIOS_ERROR');

export const fetchUsuarios = () => async (dispatch) => {
  try {
    dispatch(fetchUsuariosRequest());
    const { data } = await usuariosApi.getUsuarios();
    dispatch(fetchUsuariosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchUsuariosError(e));
    dispatch(displayError(e));
  }
};
