import { get, post, put } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getUsuarios: (count = 10) => get(`${API}/usuarios/${getUserId()}`),
  getUsuario: (id) => get(`${API}/usuario/${id}/${getUserId()}`),
  createUsuario: (form) => post(`${API}/usuario/create/${getUserId()}`, form),
  updateUsuario: (id, form) => put(`${API}/usuario/${id}/${getUserId()}`, form),
  getUsuariosStats: () => get(`${API}/usuarios/stats/${getUserId()}`),
};
