import { get, post } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getPagamentos: (page, pageSize) =>
    get(`${API}/pagamentos/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  getPagamento: (id) => get(`${API}/pagamento/detalhe/${id}/${getUserId()}`),
  getResumoDiario: (dataInicio, dataFim) =>
    get(
      `${API}/pagamentos/resumo/${getUserId()}?dataInicio=${dataInicio}&dataFim=${dataFim}`
    ),
  getPagamentosByAluno: (alunoId) =>
    get(`${API}/pagamentos/aluno/${alunoId}/${getUserId()}`),
  searchPagamentos: (page, pageSize, searchForm) =>
    post(
      `${API}/pagamentos/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
};
