import { createAction } from 'redux-actions';
import configApi from '../../utils/api/configApi';
import { displayError, displaySuccess } from './notificacoesActions';

export const getConfigRequest = createAction('GET_MENSAGEM_REQUEST');
export const getConfigSuccess = createAction('GET_MENSAGEM_SUCCESS');
export const getConfigError = createAction('GET_MENSAGEM_ERROR');

export const updateConfigRequest = createAction('UPDATE_MENSAGEM_REQUEST');
export const updateConfigSuccess = createAction('UPDATE_MENSAGEM_SUCCESS');
export const updateConfigError = createAction('UPDATE_MENSAGEM_ERROR');

export const getConfig = (id) => async (dispatch) => {
  try {
    dispatch(getConfigRequest());
    const { data } = await configApi.getConfig(id);
    dispatch(getConfigSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getConfigError(e));
    dispatch(displayError(e));
  }
};

export const updateConfig = (form) => async (dispatch) => {
  try {
    dispatch(updateConfigRequest());
    const { data } = await configApi.updateConfig(form);
    dispatch(updateConfigSuccess(data));
    dispatch(displaySuccess('Configuração atualizada com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(updateConfigError(e));
    dispatch(displayError(e));
  }
};
