import { get, post, put } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getAlunos: (page, pageSize) =>
    get(`${API}/alunos/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  getAluno: (id) => get(`${API}/aluno/${id}/${getUserId()}`),
  createAluno: (form) => post(`${API}/aluno/create/${getUserId()}`, form),
  updateAluno: (id, form) => put(`${API}/aluno/${id}/${getUserId()}`, form),
  getAlunosStats: () => get(`${API}/alunos/stats/${getUserId()}`),
  searchAlunos: (page, pageSize, searchForm) =>
    post(
      `${API}/alunos/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
};
