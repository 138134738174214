import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Spinner } from 'reactstrap';
import UserCardDetailsIcon from 'mdi-react/UserCardDetailsIcon';
import { fetchCertificados } from '../../../redux/actions/certificadosActions';

const CertificadosCadastrados = () => {
  const dispatch = useDispatch();
  const totalItems = useSelector((state) => state.certificados.totalItems);
  const isFetching = useSelector((state) => state.certificados.isFetching);

  useEffect(() => {
    dispatch(fetchCertificados(1, 1));
  }, [dispatch]);

  return (
    <Col md={6} xl={4} lg={4} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {totalItems && !isFetching && (
            <div className="mobile-app-widget">
              <div className="mobile-app-widget__top-line mobile-app-widget__top-line--blue">
                <p className="mobile-app-widget__total-stat">{totalItems}</p>
                <UserCardDetailsIcon className="dashboard__trend-icon" />
              </div>
              <div className="mobile-app-widget__title">
                <h5>Certificados Disponíveis</h5>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CertificadosCadastrados;
