import { handleActions } from 'redux-actions';
import {
  getPagamentoRequest,
  getPagamentoSuccess,
  getPagamentoError,
  fetchPagamentosRequest,
  fetchPagamentosSuccess,
  fetchPagamentosError,
  fetchPagamentosByAlunoRequest,
  fetchPagamentosByAlunoSuccess,
  fetchPagamentosByAlunoError,
  fetchSearchPagamentosRequest,
  fetchSearchPagamentosSuccess,
  getResumoDiarioRequest,
  getResumoDiarioSuccess,
  getResumoDiarioError,
} from '../actions/pagamentosActions';

const defaultState = {
  pagamento: {},
  pagamentos: [],
  resumoPagamentos: [],
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [getPagamentoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [getPagamentoSuccess](state, { payload }) {
      return {
        ...state,
        pagamento: payload,
        isFetching: false,
        error: null,
      };
    },
    [getPagamentoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchPagamentosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPagamentosSuccess](state, { payload }) {
      return {
        ...state,
        pagamentos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchPagamentosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchPagamentosByAlunoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchPagamentosByAlunoSuccess](state, { payload }) {
      return {
        ...state,
        pagamentos: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchPagamentosByAlunoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getResumoDiarioRequest](state) {
      return {
        ...state,
        pagamentos: [],
        isFetching: true,
        error: null,
      };
    },
    [getResumoDiarioSuccess](state, { payload }) {
      return {
        ...state,
        resumoPagamentos: payload,
        isFetching: false,
        error: null,
      };
    },
    [getResumoDiarioError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchPagamentosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchPagamentosSuccess](state, { payload }) {
      return {
        ...state,
        pagamentos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
