import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchInscricoesByAluno } from '../../../redux/actions/inscricoesActions';
import { BadgeInscricao } from '../../../shared/components/Badges';

const InscricoesAlunoList = ({ props }) => {
  const dispatch = useDispatch();
  const inscricoes = useSelector((state) => state.inscricoes.inscricoes);
  const totalPages = useSelector((state) => state.inscricoes.totalPages);
  const totalItems = useSelector((state) => state.inscricoes.totalItems);
  const isFetching = useSelector((state) => state.inscricoes.isFetching);
  const { id } = props.match.params;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  moment.locale('pt-br');

  const colunas = useMemo(() => [
    {
      Header: 'CERTIFICADO',
      accessor: 'certificado_titulo',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'DATA DA INSCRIÇÃO',
      accessor: 'dataInscricao',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'TENTATIVAS',
      accessor: 'tentativas',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'AÇÃO',
      accessor: 'acao',
      disableGlobalFilter: true,
      width: 65,
    },
  ]);

  const dadosTabela = (inscricoes) => {
    return inscricoes.map((inscricao) => ({
      certificado_titulo: inscricao.certificado?.titulo,
      dataInscricao: moment(inscricao.createdAt).format('l LT'),
      tentativas: inscricao.tentativas?.length,
      status: <BadgeInscricao status={inscricao.status} />,
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/inscricao/edit/${inscricao._id}`}
        >
          <EyeIcon /> Ver
        </Link>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchInscricoesByAluno(id, parseInt(page), pageSize));
  }, [dispatch, page, pageSize]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  return (
    <Fragment>
      <div className="card__title">
        <h4 className="bold-text">Inscrições</h4>
        <h4 className="subhead">
          Todos os certificados para os quais o aluno inscreveu-se
        </h4>
      </div>
      {isFetching && <Spinner className="table-fetch-spinner" />}
      {inscricoes && !isFetching && (
        <ReactTableBase
          key={'common'}
          columns={colunas}
          data={dadosTabela(inscricoes)}
          tableConfig={{
            isEditable: false,
            isResizable: false,
            isSortable: false,
            withDragAndDrop: false,
            withPagination: true,
            withSearchEngine: false,
            manualPageSize: [10, 20, 30, 40],
            placeholder: 'Search by First name...',
          }}
          setPage={setPage}
          setPageSize={setPageSize}
          currentpage={page}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
        />
      )}
    </Fragment>
  );
};

export default InscricoesAlunoList;
