import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import Panel from '@/shared/components/Panel';
import { BadgeInscricao } from '@/shared/components/Badges';
import { fetchInscricoes } from '@/redux/actions/inscricoesActions';

const header = [
  { id: 0, title: 'Nome do Aluno' },
  { id: 1, title: 'Certificado' },
  { id: 2, title: 'Data da Inscrição' },
  { id: 3, title: 'Tentativas' },
  { id: 4, title: 'Status' },
  { id: 5, title: '' },
];

const UltimasInscricoes = () => {
  const dispatch = useDispatch();
  const inscricoes = useSelector((state) => state.inscricoes.inscricoes);
  const [refresh, setRefresh] = useState(true);
  moment.locale('pt-br');

  useEffect(() => {
    if (refresh) {
      dispatch(fetchInscricoes(1, 7));
    }
  }, [dispatch, refresh]);

  return (
    <Panel
      lg={8}
      title="Últimas Inscrições"
      subhead={`Atualizado em ${moment().format('l LT')}`}
      refresh={refresh}
      setRefresh={setRefresh}
    >
      <Table responsive className="table--bordered">
        <thead>
          <tr>
            {header.map((item) => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {inscricoes.map((item) => (
            <tr key={item._id}>
              <td>{item.aluno.name}</td>
              <td>{item.certificado.titulo}</td>
              <td>{moment(item.createdAt).format('l LT')}</td>
              <td>{item.tentativas?.length}</td>
              <td>
                <BadgeInscricao status={item.status} />
              </td>
              <td>
                <Link
                  color="primary"
                  className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
                  to={`/inscricao/edit/${item._id}`}
                >
                  <EyeIcon /> Ver
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel>
  );
};

export default UltimasInscricoes;
