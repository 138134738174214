import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AlunosCadastrados from './components/AlunosCadastrados';
import CertificadosCadastrados from './components/CertificadosCadastrados';
import ResumoDiarioPagamentos from './components/ResumoDiarioPagamentos';
import ResumoInscricoes from './components/ResumoInscricoes';
import UltimasInscricoes from './components/UltimasInscricoes';
import UltimosPagamentos from './components/UltimosPagamentos';

const Dashboard = ({ rtl }) => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Dashboard</h3>
        </Col>
      </Row>
      <Row>
        <CertificadosCadastrados />
        <AlunosCadastrados />
        <ResumoDiarioPagamentos />
      </Row>
      <Row>
        <ResumoInscricoes />
        <UltimasInscricoes />
      </Row>
      <Row>
        <UltimosPagamentos />
      </Row>
    </Container>
  );
};

export default Dashboard;
