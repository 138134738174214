import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import CertificadosList from './components/CertificadosList';

const Certificados = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Certificados</h3>
        <h3 className="page-subhead subhead">
          Exibindo todos os certificados inscritos na plataforma.
        </h3>
      </Col>
    </Row>
    <Row>
      <CertificadosList />
    </Row>
  </Container>
);

export default Certificados;
