import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import { getConfig, updateConfig } from '../../../redux/actions/configActions';

const ConfigForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const config = useSelector((state) => state.config.config);
  const isFetching = useSelector((state) => state.config.isFetching);
  const error = useSelector((state) => state.config.error);

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  const onSubmit = (data) => {
    dispatch(updateConfig(data));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Col md={6} lg={9} xl={9}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Editar Configurações</h5>
          </div>

          {isFetching && <Spinner className="table-fetch-spinner" />}
          {config && !isFetching && (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={config}
            >
              {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">Título</span>
                    <div className="form__form-group-field">
                      <Field
                        name="store.title"
                        type="text"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="Título da loja/site"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Endereço</span>
                    <div className="form__form-group-field">
                      <Field
                        name="store.address"
                        type="text"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="Endereço físico da loja/site"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Telefone</span>
                    <div className="form__form-group-field">
                      <Field
                        name="store.telephone"
                        type="text"
                        component={FormField}
                        placeholder="(61) 99999-9999"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Url</span>
                    <div className="form__form-group-field">
                      <Field
                        name="store.url"
                        type="url"
                        component={FormField}
                        placeholder="www.meusite.com"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">email</span>
                    <div className="form__form-group-field">
                      <Field
                        name="store.email"
                        type="email"
                        component={FormField}
                        placeholder="meu@email.com"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="card__title">
                    <h5 className="bold-text">Integração com a CIELO</h5>
                    <h5 className="subhead">
                      Chave de identificação para integração com a Cielo. É
                      possível encontrar estes dados em{' '}
                      <a
                        href="https://cieloecommerce.cielo.com.br/Backoffice/Merchant/Account/Details"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Cielo
                      </a>
                      .
                    </h5>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">MerchantId</span>
                    <div className="form__form-group-field">
                      <Field
                        name="cielo.merchantId"
                        type="text"
                        component={FormField}
                        placeholder="código fornecido pela Cielo"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">MerchantKey</span>
                    <div className="form__form-group-field">
                      <Field
                        name="cielo.merchantKey"
                        type="text"
                        component={FormField}
                        placeholder="código fornecido pela Cielo"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="card__title">
                    <h5 className="bold-text">Integração com a Sendgrid</h5>
                    <h5 className="subhead">
                      Código de API para o envio de emails. Para obter a chave
                      de integração, acesse o site da{' '}
                      <a
                        href="https://app.sendgrid.com/settings/api_keys"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Sendgrid
                      </a>
                      .
                    </h5>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">ApiKey</span>
                    <div className="form__form-group-field">
                      <Field
                        name="sendgrid.apiKey"
                        type="text"
                        component={FormField}
                        placeholder="código fornecido pela Cielo"
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button type="button" onClick={() => setIsRedirect(true)}>
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

ConfigForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

ConfigForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default ConfigForm;
