import { createAction } from 'redux-actions';
import alunosApi from '../../utils/api/alunosApi';
import { displayError, displaySuccess } from './notificacoesActions';

export const fetchAlunosRequest = createAction('FETCH_ALUNOS_REQUEST');
export const fetchAlunosSuccess = createAction('FETCH_ALUNOS_SUCCESS');
export const fetchAlunosError = createAction('FETCH_ALUNOS_ERROR');

export const getAlunoRequest = createAction('GET_ALUNO_REQUEST');
export const getAlunoSuccess = createAction('GET_ALUNO_SUCCESS');
export const getAlunoError = createAction('GET_ALUNO_ERROR');

export const createAlunoRequest = createAction('CREATE_ALUNO_REQUEST');
export const createAlunoSuccess = createAction('CREATE_ALUNO_SUCCESS');
export const createAlunoError = createAction('CREATE_ALUNO_ERROR');

export const updateAlunoRequest = createAction('UPDATE_ALUNO_REQUEST');
export const updateAlunoSuccess = createAction('UPDATE_ALUNO_SUCCESS');
export const updateAlunoError = createAction('UPDATE_ALUNO_ERROR');

export const newAlunoRequest = createAction('NEW_ALUNO_REQUEST');

export const fetchAlunosStatsRequest = createAction(
  'FETCH_ALUNOS_STATS_REQUEST'
);
export const fetchAlunosStatsSuccess = createAction(
  'FETCH_ALUNOS_STATS_SUCCESS'
);
export const fetchAlunosStatsError = createAction('FETCH_ALUNOS_STATS_ERROR');

export const fetchSearchAlunosRequest = createAction(
  'FETCH_SEARCH_ALUNOS_REQUEST'
);
export const fetchSearchAlunosSuccess = createAction(
  'FETCH_SEARCH_ALUNOS_SUCCESS'
);

export const fetchAlunos = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchAlunosRequest());
    const { data } = await alunosApi.getAlunos(page, pageSize);
    dispatch(fetchAlunosSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchAlunosError());
    dispatch(displayError(e));
  }
};

export const createAluno = (form) => async (dispatch) => {
  try {
    dispatch(createAlunoRequest());
    const { data } = await alunosApi.createAluno(form);
    dispatch(createAlunoSuccess(data));
    dispatch(displaySuccess('Aluno incluído com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(createAlunoError(e));
    dispatch(displayError(e));
  }
};

export const getAluno = (id) => async (dispatch) => {
  try {
    dispatch(getAlunoRequest());
    const { data } = await alunosApi.getAluno(id);
    dispatch(getAlunoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getAlunoError(e));
    dispatch(displayError(e));
  }
};

export const updateAluno = (id, form) => async (dispatch) => {
  try {
    dispatch(updateAlunoRequest());
    const { data } = await alunosApi.updateAluno(id, form);
    dispatch(updateAlunoSuccess(data));
    dispatch(displaySuccess('Aluno atualizado com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(updateAlunoError(e));
    dispatch(displayError(e));
  }
};

export const newAluno = () => async (dispatch) => {
  dispatch(newAlunoRequest());
};

export const fetchAlunosStats = () => async (dispatch) => {
  try {
    dispatch(fetchAlunosStatsRequest());
    const { data } = await alunosApi.getAlunosStats();
    dispatch(fetchAlunosStatsSuccess(data));
  } catch (e) {
    dispatch(fetchAlunosStatsError());
    dispatch(displayError(e));
  }
};
export const fetchSearchAlunos =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchAlunosRequest());
      const { data } = await alunosApi.searchAlunos(page, pageSize, search);
      dispatch(fetchSearchAlunosSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
    }
  };
