import React, { useEffect, useState, useMemo, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchPagamentosByAluno } from '../../../redux/actions/pagamentosActions';
import { BadgePagamento } from '../../../shared/components/Badges';

const imagensPagamento = {
  cupom: `${process.env.PUBLIC_URL}/img/cards/cupom.svg`,
  cartaocredito: `${process.env.PUBLIC_URL}/img/cards/cartao.svg`,
  boletobancario: `${process.env.PUBLIC_URL}/img/cards/boleto.svg`,
  pix: `${process.env.PUBLIC_URL}/img/cards/pix.svg`,
};

const PagamentosAlunoList = ({ props }) => {
  const dispatch = useDispatch();
  const pagamentos = useSelector((state) => state.pagamentos.pagamentos);
  const totalPages = useSelector((state) => state.pagamentos.totalPages);
  const totalItems = useSelector((state) => state.pagamentos.totalItems);
  const isFetching = useSelector((state) => state.pagamentos.isFetching);
  const { id } = props.match.params;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  moment.locale('pt-br');

  const colunas = useMemo(() => [
    {
      Header: 'CERTIFICADO',
      accessor: 'certificado_titulo',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'DATA',
      accessor: 'dataPagamento',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'VALOR',
      accessor: 'valor',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'FORMA DE PAGAMENTO',
      accessor: 'forma_pagamento',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'AÇÃO',
      accessor: 'acao',
      disableGlobalFilter: true,
      width: 65,
    },
  ]);

  const dadosTabela = (pagamentos) => {
    return pagamentos.map((pagamento) => ({
      certificado_titulo: pagamento.certificado?.titulo,
      dataPagamento: moment(pagamento.createdAt).format('l LT'),
      valor: `R$${pagamento.valor}`,
      forma_pagamento: (
        <img
          className="payment__credit-card_img"
          src={imagensPagamento[pagamento.forma_pagamento]}
          alt="visa"
        />
      ),
      status: <BadgePagamento status={pagamento.status} />,
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/pagamento/edit/${pagamento._id}`}
        >
          <EyeIcon /> Ver
        </Link>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchPagamentosByAluno(id, parseInt(page), pageSize));
  }, [dispatch, page, pageSize]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  return (
    <Fragment>
      <div className="card__title">
        <h4 className="bold-text">Pagamentos</h4>
        <h4 className="subhead">Todos os pagamentos efetuados pelo aluno</h4>
      </div>
      {isFetching && <Spinner className="table-fetch-spinner" />}
      {pagamentos && !isFetching && (
        <ReactTableBase
          key={'common'}
          columns={colunas}
          data={dadosTabela(pagamentos)}
          tableConfig={{
            isEditable: false,
            isResizable: false,
            isSortable: false,
            withDragAndDrop: false,
            withPagination: true,
            withSearchEngine: false,
            manualPageSize: [10, 20, 30, 40],
            placeholder: 'Search by First name...',
          }}
          setPage={setPage}
          setPageSize={setPageSize}
          currentpage={page}
          pageSize={pageSize}
          totalPages={totalPages}
          totalItems={totalItems}
        />
      )}
    </Fragment>
  );
};

export default PagamentosAlunoList;
