import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Progress,
  Spinner,
  Table,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseCircleOutlineIcon from 'mdi-react/CloseCircleOutlineIcon';
import { getInscricao } from '../../../redux/actions/inscricoesActions';
import { Link } from 'react-router-dom';
import { BadgePagamento } from '../../../shared/components/Badges';

const imagensPagamento = {
  cupom: `${process.env.PUBLIC_URL}/img/cards/cupom.svg`,
  cartaocredito: `${process.env.PUBLIC_URL}/img/cards/cartao.svg`,
  boletobancario: `${process.env.PUBLIC_URL}/img/cards/boleto.svg`,
  pix: `${process.env.PUBLIC_URL}/img/cards/pix.svg`,
};

const DetalheInscricao = ({ props }) => {
  const dispatch = useDispatch();
  const inscricao = useSelector((state) => state.inscricoes.inscricao);
  const isFetching = useSelector((state) => state.inscricoes.isFetching);
  const { id } = props.match.params;
  moment.locale('pt-br');

  useEffect(() => {
    dispatch(getInscricao(id));
  }, [dispatch, id]);

  return (
    <Fragment>
      {isFetching && <Spinner className="loading-spinner" />}
      {inscricao && !isFetching && (
        <Fragment>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <table className="project-summary__info">
                  <tbody>
                    <tr>
                      <th>Inscrição</th>
                      <td>#{inscricao?._id}</td>
                    </tr>
                    <tr>
                      <th>Data:</th>
                      <td>{moment(inscricao?.createdAt).format('ll LT')}</td>
                    </tr>
                    <tr>
                      <th>Aluno:</th>
                      <td>{inscricao?.aluno?.name}</td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>{inscricao?.aluno?.email}</td>
                    </tr>
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Ações</h5>
                </div>{' '}
                <ButtonToolbar>
                  <Link
                    to={`/aluno/edit/${inscricao?.aluno?._id}`}
                    className="btn btn-secondary"
                  >
                    Atualizar cadastro
                  </Link>
                  {inscricao.status === 'concluído' && (
                    <a
                      color="primary"
                      href={`https://www.certificadosnerdflix.com.br/certificado/${id}`}
                      className="btn btn-primary"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Emitir Certificado
                    </a>
                  )}
                </ButtonToolbar>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Detalhes do Certificado</h5>
                </div>
                <Table className="table--bordered" responsive>
                  <thead>
                    <tr>
                      <th>Título</th>
                      <th>Carga horária</th>
                      <th>Nota de aprovação</th>
                      <th>Tempo de prova</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{inscricao?.certificado?.titulo}</td>
                      <td>{inscricao?.certificado?.carga_horaria}hs</td>
                      <td>{inscricao?.certificado?.nota_aprovacao}%</td>
                      <td>{inscricao?.certificado?.tempo_prova}min.</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Tentativas</h5>
                </div>
                <Table className="table--bordered" responsive>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Nota</th>
                      <th>Passou?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inscricao?.tentativas?.map((tentativa, index) => (
                      <tr>
                        <td>{moment(tentativa.data).format('ll LT')}</td>
                        <td>
                          <div className="progress-wrap progress-wrap--blue">
                            <Progress
                              value={tentativa.percentual}
                            >{`${tentativa.percentual}%`}</Progress>
                          </div>
                        </td>
                        <td>
                          {tentativa.aprovado ? (
                            <CheckIcon className="icon--success" />
                          ) : (
                            <CloseCircleOutlineIcon className="icon--danger" />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col md={12} lg={6}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Pagamentos</h5>
                </div>
                <Table className="table--bordered" responsive>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Valor</th>
                      <th>Forma de Pagamento</th>
                      <th>Situação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {moment(inscricao?.pagamento?.createdAt).format('l LT')}
                      </td>
                      <td>R${inscricao?.pagamento?.valor}</td>
                      <td>
                        <img
                          className="payment__credit-card_img"
                          src={
                            imagensPagamento[
                              inscricao?.pagamento?.forma_pagamento
                            ]
                          }
                          alt="visa"
                        />
                      </td>
                      <td>
                        <BadgePagamento status={inscricao?.pagamento?.status} />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetalheInscricao;
