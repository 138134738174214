import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

const SidebarContent = ({ onClick }) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        title="Painel"
        icon="home"
        route="/dashboard"
        onClick={onClick}
      />
      <SidebarLink
        title="Certificados"
        icon="graduation-hat"
        route="/certificados"
        onClick={onClick}
      />
      <SidebarLink
        title="Alunos"
        icon="user"
        route="/alunos"
        onClick={onClick}
      />
      <SidebarLink
        title="Inscrições"
        icon="license"
        route="/inscricoes"
        onClick={onClick}
      />
      <SidebarLink
        title="Pagamentos"
        icon="cart"
        route="/pagamentos"
        onClick={onClick}
      />
      <SidebarLink
        title="Cupons"
        icon="tag"
        route="/cupons"
        onClick={onClick}
      />
      <SidebarLink
        title="Mensagens"
        icon="envelope"
        route="/mensagens"
        onClick={onClick}
      />
      <SidebarLink
        title="Configurações"
        icon="cog"
        route="/config"
        onClick={onClick}
      />
    </ul>
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
