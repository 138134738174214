import { handleActions } from 'redux-actions';
import {
  fetchAlunosRequest,
  fetchAlunosSuccess,
  fetchAlunosError,
  getAlunoRequest,
  getAlunoSuccess,
  getAlunoError,
  createAlunoRequest,
  createAlunoSuccess,
  createAlunoError,
  updateAlunoRequest,
  updateAlunoSuccess,
  updateAlunoError,
  newAlunoRequest,
  fetchAlunosStatsRequest,
  fetchAlunosStatsSuccess,
  fetchAlunosStatsError,
  fetchSearchAlunosRequest,
  fetchSearchAlunosSuccess,
} from '../actions/alunosActions';

const defaultState = {
  alunos: [],
  totalPages: 0,
  aluno: {},
  count: 0,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchAlunosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchAlunosSuccess](state, { payload }) {
      return {
        ...state,
        alunos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchAlunosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getAlunoRequest](state) {
      return {
        ...state,
        aluno: {},
        isFetching: true,
        error: null,
      };
    },
    [getAlunoSuccess](state, { payload }) {
      return {
        ...state,
        aluno: payload,
        isFetching: false,
        error: null,
      };
    },
    [getAlunoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createAlunoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [createAlunoSuccess](state, { payload }) {
      return {
        ...state,
        aluno: payload,
        isFetching: false,
        error: null,
      };
    },
    [createAlunoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [updateAlunoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [updateAlunoSuccess](state, { payload }) {
      return {
        ...state,
        aluno: payload,
        isFetching: false,
        error: null,
      };
    },
    [updateAlunoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [newAlunoRequest](state, { payload }) {
      return {
        ...state,
        aluno: '',
        isFetching: false,
        error: payload,
      };
    },
    [fetchAlunosStatsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchAlunosStatsSuccess](state, { payload }) {
      return {
        ...state,
        count: payload.countAlunos,
        isFetching: false,
        error: null,
      };
    },
    [fetchAlunosStatsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [fetchSearchAlunosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchSearchAlunosSuccess](state, { payload }) {
      return {
        ...state,
        alunos: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
