import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'react-final-form';
import { fetchCertificados } from '@/redux/actions/certificadosActions';
import renderSelectField from '@/shared/components/form/Select';

const CertificadoSelect = ({ name }) => {
  const dispatch = useDispatch();
  const certificados = useSelector((state) => state.certificados.certificados);

  useEffect(() => {
    dispatch(fetchCertificados(1, 999));
  }, [dispatch]);

  return (
    <div className="form__form-group">
      <div className="form__form-group-field">
        <Field
          name={name}
          component={renderSelectField}
          type="text"
          placeholder="Certificado"
          options={[{ value: undefined, label: 'Todos' }].concat(
            certificados.map((certificado) => ({
              value: `${certificado._id}`,
              label: certificado.titulo,
            }))
          )}
        />
      </div>
    </div>
  );
};

export default CertificadoSelect;
