import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import InscricoesList from './components/InscricoesList';

const Inscricoes = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Inscrições</h3>
        <h3 className="page-subhead subhead">
          Exibindo todas as inscrições feitas na plataforma, mostrando as mais
          recentes primeiro
        </h3>
      </Col>
    </Row>
    <Row>
      <InscricoesList />
    </Row>
  </Container>
);

export default Inscricoes;
