import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import MensagensList from './components/MensagensList';

const Mensagens = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Mensagens</h3>
        <h3 className="page-subhead subhead">
          Mensagens enviadas automaticamente para os alunos por Email, Whatsapp
          ou Telegram
        </h3>{' '}
      </Col>
    </Row>
    <Row>
      <MensagensList />
    </Row>
  </Container>
);

export default Mensagens;
