import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';
import LogIn from '../LogIn/index';
import Aluno from '../Aluno/index';
import Alunos from '../Alunos/index';
import Certificado from '../Certificado/index';
import Certificados from '../Certificados/index';
import Config from '../Config/index';
import Cupom from '../Cupom/index';
import Cupons from '../Cupons/index';
import Dashboard from '../Dashboard/index';
import Inscricao from '../Inscricao/index';
import Inscricoes from '../Inscricoes/index';
import Mensagem from '../Mensagem/index';
import Mensagens from '../Mensagens/index';
import Pagamento from '../Pagamento/index';
import Pagamentos from '../Pagamentos/index';

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <PrivateRoute path="/aluno/novo" component={Aluno} />
      <PrivateRoute path="/aluno/edit/:id" component={Aluno} />
      <PrivateRoute path="/alunos" component={Alunos} />
      <PrivateRoute path="/certificado/novo" component={Certificado} />
      <PrivateRoute path="/certificado/edit/:id" component={Certificado} />{' '}
      <PrivateRoute path="/certificados" component={Certificados} />
      <PrivateRoute path="/config" component={Config} />
      <PrivateRoute path="/cupom/novo" component={Cupom} />
      <PrivateRoute path="/cupom/edit/:id" component={Cupom} />
      <PrivateRoute path="/cupons" component={Cupons} />
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/inscricao/edit/:id" component={Inscricao} />
      <PrivateRoute path="/inscricoes" component={Inscricoes} />
      <PrivateRoute path="/mensagem/novo" component={Mensagem} />
      <PrivateRoute path="/mensagem/edit/:id" component={Mensagem} />{' '}
      <PrivateRoute path="/mensagens" component={Mensagens} />
      <PrivateRoute path="/pagamento/edit/:id" component={Pagamento} />
      <PrivateRoute path="/pagamentos" component={Pagamentos} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/login" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
