/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.codigo) {
    errors.codigo = 'O campo Código não pode ser vazio';
  }
  if (!values.titulo) {
    errors.titulo = 'O campo Título não pode ser vazio';
  }
  if (!values.descricao) {
    errors.descricao = 'O campo Descrição não pode ser vazio';
  }
  if (!values.assunto) {
    errors.assunto = 'O campo Assunto não pode ser vazio';
  }
  if (!values.corpo) {
    errors.corpo = 'O campo Corpo não pode ser vazio';
  }
  if (!values.canal) {
    errors.canal = 'Selecione uma opção, por favor';
  }
  return errors;
};

export default validate;
