import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonToolbar, Card, CardBody, Col, Spinner } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import EnvelopeOutlineIcon from 'mdi-react/EnvelopeOutlineIcon';
import WhatsappIcon from 'mdi-react/WhatsappIcon';
import { Link } from 'react-router-dom';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchMensagens } from '../../../redux/actions/mensagensActions';

const MensagensList = () => {
  const dispatch = useDispatch();
  const mensagens = useSelector((state) => state.mensagens.mensagens);
  const totalPages = useSelector((state) => state.mensagens.totalPages);
  const totalItems = useSelector((state) => state.mensagens.totalItems);
  const isFetching = useSelector((state) => state.mensagens.isFetching);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const colunas = useMemo(
    () => [
      {
        Header: 'TÍTULO',
        accessor: 'titulo',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'DESCRIÇÃO',
        accessor: 'descricao',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'ATIVO',
        accessor: 'ativo',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'CANAL',
        accessor: 'canal',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'AÇÃO',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 65,
      },
    ],
    []
  );

  const dadosTabela = (mensagens) => {
    return mensagens.map((mensagem) => ({
      codigo: mensagem.codigo,
      titulo: mensagem.titulo,
      descricao: mensagem.descricao,
      ativo: mensagem.ativo ? (
        <span className={`inbox__label-color inbox__label-color--green`} />
      ) : (
        <span className={`inbox__label-color inbox__label-color--red`} />
      ),
      canal:
        mensagem.canal === 'email' ? <EnvelopeOutlineIcon /> : <WhatsappIcon />,
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/mensagem/edit/${mensagem._id}`}
        >
          <EditIcon /> Editar
        </Link>
      ),
    }));
  };

  // fetch mensagens when page load
  useEffect(() => {
    dispatch(fetchMensagens(parseInt(page), pageSize));
  }, [dispatch, page, pageSize]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Mensagens cadastradas</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link
                className="btn btn-primary list__btn-add"
                to="/mensagem/novo"
              >
                <PlusIcon /> Nova mensagem
              </Link>
            </ButtonToolbar>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {mensagens && !isFetching && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(mensagens)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default MensagensList;
