import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonToolbar,
  Button,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import EditIcon from 'mdi-react/EditIcon';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchAlunos, fetchSearchAlunos } from '@/redux/actions/alunosActions';
import { BadgeAluno } from '../../../shared/components/Badges';
import { listaSituacaoAluno } from '../../../utils/helpers';

const AlunosList = () => {
  const dispatch = useDispatch();
  const alunos = useSelector((state) => state.alunos.alunos);
  const totalPages = useSelector((state) => state.alunos.totalPages);
  const totalItems = useSelector((state) => state.alunos.totalItems);
  const isFetching = useSelector((state) => state.alunos.isFetching);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});

  const colunas = useMemo(
    () => [
      {
        Header: 'NOME',
        accessor: 'nome',
        disableGlobalFilter: true,
        width: 100,
      },
      {
        Header: 'EMAIL',
        accessor: 'email',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'ID USCREEN',
        accessor: 'id_uscreen',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'STATUS',
        accessor: 'status',
        disableGlobalFilter: true,
        width: 80,
      },
      {
        Header: 'AÇÃO',
        accessor: 'acao',
        disableGlobalFilter: true,
        width: 65,
      },
    ],
    []
  );

  const dadosTabela = (alunos) => {
    return alunos.map((aluno) => ({
      nome: aluno.name,
      email: aluno.email,
      id_uscreen: aluno.id_uscreen,
      status: <BadgeAluno status={aluno.status} />,
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/aluno/edit/${aluno._id}`}
        >
          <EditIcon /> Editar
        </Link>
      ),
    }));
  };

  // fetch alunos when page load
  useEffect(() => {
    dispatch(fetchSearchAlunos(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    searchForm.filters = {
      status: data.statusSelect?.value,
    };
    setSearch(searchForm);
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Alunos cadastrados</h5>
            <ButtonToolbar className="list__btn-toolbar-top">
              <Link className="btn btn-primary list__btn-add" to="/aluno/novo">
                <PlusIcon /> Novo aluno
              </Link>
            </ButtonToolbar>
          </div>
          <div className="search__form">
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="search"
                          type="text"
                          component={FormField}
                          placeholder="Pesquisa..."
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="statusSelect"
                          component={renderSelectField}
                          type="text"
                          placeholder="Situação"
                          options={(() =>
                            [].concat(
                              [{ value: undefined, label: 'Todos' }],
                              listaSituacaoAluno
                            ))()}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} className="align-self-end text-end">
                    <Button color="primary" type="submit">
                      Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {alunos && !isFetching && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(alunos)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default AlunosList;
