import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Col,
  Spinner,
  Card,
  CardBody,
  Row,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import renderSelectField from '@/shared/components/form/Select';
import renderMultiSelectField from '@/shared/components/form/MultiSelect';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getCupom,
  createCupom,
  updateCupom,
  newCupom,
} from '../../../redux/actions/cuponsActions';
import { fetchCertificados } from '../../../redux/actions/certificadosActions';
import { fetchAlunos } from '../../../redux/actions/alunosActions';

const CupomForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const cupom = useSelector((state) => state.cupons.cupom);
  const certificados = useSelector((state) => state.certificados.certificados);
  const alunos = useSelector((state) => state.alunos.alunos);
  const isFetching = useSelector((state) => state.cupons.isFetching);
  const error = useSelector((state) => state.cupons.error);
  const { id } = props.match.params;
  const isAddMode = !id;

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getCupom(id));
    } else {
      dispatch(newCupom());
    }
    dispatch(fetchCertificados(1, 50));
    dispatch(fetchAlunos(1, 50));
  }, [dispatch]);

  if (!isAddMode) {
    if (cupom?.tipo_desconto) {
      cupom.tipoSelect = {
        value: cupom.tipo_desconto,
        label: cupom.tipo_desconto.replace(/\w/, (chr) => chr.toUpperCase()),
      };
    }
    if (cupom?.valido_ate) {
      cupom.validade = cupom.valido_ate.substr(0, 'YYYY-MM-DD'.length);
    }
    if (cupom?.produtos_aplicaveis?.length > 0 && certificados) {
      cupom.produtosSelect = cupom.produtos_aplicaveis.map((id) => {
        let obj = {};
        for (let certificado of certificados) {
          if (certificado._id == id) {
            obj.value = id;
            obj.label = certificado.titulo;
            break;
          }
        }
        return obj;
      });
    }
    if (cupom?.alunos_aplicaveis?.length > 0 && alunos) {
      cupom.alunosSelect = cupom.alunos_aplicaveis.map((id) => {
        let obj = {};
        for (let aluno of alunos) {
          if (aluno._id == id) {
            obj.value = id;
            obj.label = aluno.name;
            break;
          }
        }
        return obj;
      });
    }
  }

  const onSubmit = (data) => {
    const formData = {};
    const fields = Object.keys(data).map((key, i) => key);
    fields.map((item) => (formData[item] = data[item]));
    formData.tipo_desconto = data.tipoSelect.value;
    formData.valido_ate = data.validade ? `${data.validade} 00:00:00` : null;
    if (data.produtosSelect) {
      formData.produtos_aplicaveis = data.produtosSelect.map(
        (produto) => produto.value
      );
    }
    if (data.alunosSelect) {
      formData.alunos_aplicaveis = data.alunosSelect.map(
        (aluno) => aluno.value
      );
    }

    isAddMode
      ? dispatch(createCupom(formData))
      : dispatch(updateCupom(id, formData));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/cupons" />;
  }

  return (
    <Col xs={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h4 className="bold-text">{isAddMode ? 'Novo' : 'Editar'} Cupom</h4>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {(cupom || isAddMode) && !isFetching && (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={isAddMode ? {} : cupom}
            >
              {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <div className="form__form-group">
                    <span className="form__form-group-label">Código</span>
                    <div className="form__form-group-field">
                      <Field
                        name="codigo"
                        type="text"
                        component={FormField}
                        rules={{ required: 'Campo Obrigatório' }}
                        isAboveError={isAboveError}
                        placeholder="Código do Cupom"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Tipo</span>
                    <div className="form__form-group-field">
                      <Field
                        name="tipoSelect"
                        component={renderSelectField}
                        type="text"
                        options={[
                          { value: 'percentual', label: 'Percentual' },
                          { value: 'valor', label: 'Valor' },
                        ]}
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Valor</span>
                    <div className="form__form-group-field">
                      <Field
                        name="valor_desconto"
                        type="number"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="15"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Aplicações</span>
                    <div className="form__form-group-field">
                      <Field
                        name="quantidade_aplicavel"
                        type="number"
                        component={FormField}
                        isAboveError={isAboveError}
                        placeholder="1"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Valido Até</span>
                    <div className="form__form-group-field">
                      <Field
                        name="validade"
                        type="date"
                        component={FormField}
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Produtos Aplicaveis
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="produtosSelect"
                        type="text"
                        component={renderMultiSelectField}
                        options={certificados.map((certificado) => ({
                          value: certificado._id,
                          label: certificado.titulo,
                        }))}
                        /*defaultValue={{ value: 'any', label: 'Todos' }}*/
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Alunos Aplicaveis
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="alunosSelect"
                        type="text"
                        component={renderMultiSelectField}
                        options={alunos.map((aluno) => ({
                          value: aluno._id,
                          label: aluno.name,
                        }))}
                        /*defaultValue={{ value: 'any', label: 'Todos' }}*/
                        isAboveError={isAboveError}
                      />
                    </div>
                  </div>

                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" type="submit">
                      Salvar
                    </Button>
                    <Button type="button" onClick={() => setIsRedirect(true)}>
                      Cancelar
                    </Button>
                  </ButtonToolbar>
                </form>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

CupomForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

CupomForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default CupomForm;
