import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToolbar, Col, Spinner } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import renderSelectField from '@/shared/components/form/Select';
import FormField from '@/shared/components/form/FormField';
import validate from './validate';
import {
  getAluno,
  createAluno,
  updateAluno,
  newAluno,
} from '../../../redux/actions/alunosActions';

const AlunoForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const aluno = useSelector((state) => state.alunos.aluno);
  const isFetching = useSelector((state) => state.alunos.isFetching);
  const error = useSelector((state) => state.alunos.error);
  const { id } = props.match.params;
  const isAddMode = !id;

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getAluno(id));
    } else {
      dispatch(newAluno());
    }
  }, [dispatch]);

  if (aluno) {
    aluno.statusSelect = { value: aluno.status, label: aluno.status };
  }

  const onSubmit = (data) => {
    const formData = {};
    const fields = Object.keys(data).map((key, i) => key);
    fields.map((item) => (formData[item] = data[item]));
    formData.status = data.statusSelect.value;

    isAddMode
      ? dispatch(createAluno(formData))
      : dispatch(updateAluno(id, formData));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/alunos" />;
  }

  return (
    <Fragment>
      <Col xl={9}>
        <div className="card__title">
          <h4 className="bold-text">Cadastro</h4>
          <h4 className="subhead">Informações cadastrais do aluno</h4>
        </div>
        {isFetching && <Spinner className="table-fetch-spinner" />}
        {(aluno || isAddMode) && !isFetching && (
          <Form onSubmit={onSubmit} validate={validate} initialValues={aluno}>
            {({ handleSubmit, form: { reset, pristine, submitting } }) => (
              <form
                className={`form ${isHorizontal && 'form--horizontal'}`}
                onSubmit={handleSubmit}
              >
                <div className="form__form-group">
                  <span className="form__form-group-label">Nome</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      type="text"
                      component={FormField}
                      rules={{ required: 'Campo Obrigatório' }}
                      isAboveError={isAboveError}
                      placeholder="Nome do aluno"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">E-mail</span>
                  <div className="form__form-group-field">
                    <Field
                      name="email"
                      type="email"
                      component={FormField}
                      isAboveError={isAboveError}
                      placeholder="exemplo@gmail.com"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Id Uscreen</span>
                  <div className="form__form-group-field">
                    <Field
                      name="id_uscreen"
                      type="text"
                      component={FormField}
                      placeholder="999999"
                      isAboveError={isAboveError}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Status</span>
                  <div className="form__form-group-field">
                    <Field
                      name="statusSelect"
                      component={renderSelectField}
                      type="text"
                      options={[
                        { value: 'ativo', label: 'ativo' },
                        { value: 'inativo', label: 'inativo' },
                      ]}
                      isAboveError={isAboveError}
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit">
                    Salvar
                  </Button>
                  <Button type="button" onClick={() => setIsRedirect(true)}>
                    Cancelar
                  </Button>
                </ButtonToolbar>
              </form>
            )}
          </Form>
        )}
      </Col>
    </Fragment>
  );
};

AlunoForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

AlunoForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default AlunoForm;
