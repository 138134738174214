/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (values.store) {
    if (!values.store.title) {
      errors.store.title = 'O campo Título não pode ser vazio';
    }
    if (!values.store?.url) {
      errors.store.url = 'O campo URL não pode ser vazio';
    }
    if (!values.store.email) {
      errors.store.email = 'O campo Email não pode ser vazio';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.store.email)
    ) {
      errors.store.email = 'Email inválido';
    }
  }
  return errors;
};

export default validate;
