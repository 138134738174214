import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import Panel from '@/shared/components/Panel';
import { BadgePagamento } from '@/shared/components/Badges';
import { fetchPagamentos } from '@/redux/actions/pagamentosActions';

const imagensPagamento = {
  cupom: `${process.env.PUBLIC_URL}/img/cards/cupom.svg`,
  cartaocredito: `${process.env.PUBLIC_URL}/img/cards/cartao.svg`,
  boletobancario: `${process.env.PUBLIC_URL}/img/cards/boleto.svg`,
  pix: `${process.env.PUBLIC_URL}/img/cards/pix.svg`,
};

const header = [
  { id: 0, title: 'Aluno' },
  { id: 1, title: 'Certificado' },
  { id: 2, title: 'Data' },
  { id: 3, title: 'Valor' },
  { id: 4, title: 'Forma de Pagamento' },
  { id: 5, title: 'Status' },
  { id: 6, title: '' },
];

const UltimosPagamentos = () => {
  const dispatch = useDispatch();
  const pagamentos = useSelector((state) => state.pagamentos.pagamentos);
  const [refresh, setRefresh] = useState(true);
  moment.locale('pt-br');

  useEffect(() => {
    if (refresh) {
      dispatch(fetchPagamentos(1, 7));
    }
  }, [dispatch, refresh]);

  return (
    <Panel
      lg={12}
      title="Últimos Pagamentos"
      subhead={`Atualizado em ${moment().format('l LT')}`}
      refresh={refresh}
      setRefresh={setRefresh}
    >
      <Table responsive className="table--bordered">
        <thead>
          <tr>
            {header.map((item) => (
              <th key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {pagamentos.map((item) => (
            <tr key={item._id}>
              <td>{item.aluno?.name}</td>
              <td>{item.certificado?.titulo}</td>
              <td>{moment(item.createdAt).format('l LT')}</td>
              <td>{item.valor}</td>
              <td>
                <img
                  className="payment__credit-card_img"
                  src={imagensPagamento[item.forma_pagamento]}
                  alt="visa"
                />
              </td>
              <td>
                <BadgePagamento status={item.status} />
              </td>
              <td>
                <Link
                  color="primary"
                  className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
                  to={`/pagamento/edit/${item._id}`}
                >
                  <EyeIcon /> Ver
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel>
  );
};

export default UltimosPagamentos;
