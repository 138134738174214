import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import DetalhePagamento from './components/DetalhePagamento';

const Pagamento = (props) => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Detalhes do Pagamento</h3>
        <h3 className="page-subhead subhead">
          Verifique o detalhe do pagamento
        </h3>
      </Col>
    </Row>
    <Row>
      <DetalhePagamento props={props} />
    </Row>
  </Container>
);

export default Pagamento;
