/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.titulo) {
    errors.titulo = 'O campo Título não pode ser vazio';
  }
  if (!values.descricao) {
    errors.descricao = 'O campo Descrição não pode ser vazio';
  }
  if (!values.conteudo_programa) {
    errors.conteudo_programa =
      'O campo Conteúdo Programático não pode ser vazio';
  }
  if (!values.tempo_prova) {
    errors.tempo_prova = 'O campo Tempo de Prova não pode ser vazio';
  }
  if (!values.nota_aprovacao) {
    errors.nota_aprovacao = 'O campo Nota para Aprovação não pode ser vazio';
  }
  if (!values.carga_horaria) {
    errors.carga_horaria = 'O campo Carga Horária não pode ser vazio';
  }
  if (!values.tentativas) {
    errors.tentativas = 'O campo Tentativas não pode ser vazio';
  }
  if (!values.valor) {
    errors.valor = 'O campo Valor não pode ser vazio';
  }
  if (!values.professorSelect) {
    errors.professorSelect = 'Selecione uma opção';
  }
  if (!values.statusSelect) {
    errors.statusSelect = 'Selecione uma opção';
  }
  return errors;
};

export default validate;
