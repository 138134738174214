import { get, post, put } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getCupons: (page, pageSize) =>
    get(`${API}/cupons/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  getCupom: (id) => get(`${API}/cupom/${id}/${getUserId()}`),
  createCupom: (form) => post(`${API}/cupom/create/${getUserId()}`, form),
  updateCupom: (id, form) => put(`${API}/cupom/${id}/${getUserId()}`, form),
};
