export const API = process.env.REACT_APP_API_URL;

export const getListaSubsecao = () => {
  return [
    'Águas Claras',
    'Brazlândia',
    'Ceilândia',
    'Gama e Santa Maria',
    'Guará',
    'Núcleo Bandeirante',
    'Paranoá',
    'Planaltina',
    'Riacho Fundo I e II e Recanto das Emas',
    'Samambaia',
    'São Sebastião',
    'Sobradinho',
    'Taguatinga',
  ];
};

export const listaPerfisAcesso = () => {
  return [
    { value: 0, label: 'Apoiador' },
    { value: 1, label: 'Gestor Campanha' },
    { value: 2, label: 'Admin' },
  ];
};

export const getOidcStorageKey = () => {
  const authSettings = JSON.parse(localStorage.getItem('authSettings'));
  if (authSettings) {
    return `oidc.user:${authSettings.auth_server}:${authSettings.client_id}`;
  }
  return null;
};

export const getOidcInfo = () => {
  const key = getOidcStorageKey();
  if (key) {
    const oidc = JSON.parse(localStorage.getItem(key));
    return oidc;
  }
  return null;
};

export const getToken = () => {
  const user = JSON.parse(localStorage.getItem('adminNerdflixUser'));

  if (user) {
    return user.token;
  } else {
    return '';
  }
};

export const getUserId = () => {
  const { user } = JSON.parse(localStorage.getItem('adminNerdflixUser'));

  if (user) {
    return user._id;
  } else {
    return '';
  }
};

export const listaSituacaoAluno = [
  { value: 'ativo', label: 'ativo' },
  { value: 'inativo', label: 'inativo' },
];

export const listaSituacaoCertificado = [
  { value: 'inscrito', label: 'inscrito' },
  { value: 'em execução', label: 'em execução' },
  { value: 'concluído', label: 'concluído' },
  { value: 'falhou', label: 'falhou' },
];

export const listaSituacaoPagamento = [
  { value: 'Pendente', label: 'Pendente' },
  { value: 'Aguardando', label: 'Aguardando' },
  { value: 'Pago', label: 'Pago' },
  { value: 'Falhou', label: 'Falhou' },
  { value: 'Devolvido', label: 'Devolvido' },
];

export const listaTipoPagamento = [
  { value: 'boletobancario', label: 'Boleto Bancario' },
  { value: 'cartaocredito', label: 'Cartao de Credito' },
  { value: 'cupom', label: 'Cupom' },
  { value: 'pix', label: 'Pix' },
];

export const imagensPagamento = {
  cupom: `${process.env.PUBLIC_URL}/img/cards/cupom.svg`,
  cartaocredito: `${process.env.PUBLIC_URL}/img/cards/cartao.svg`,
  boletobancario: `${process.env.PUBLIC_URL}/img/cards/boleto.svg`,
  pix: `${process.env.PUBLIC_URL}/img/cards/pix.svg`,
};
