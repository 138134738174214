import { createAction } from 'redux-actions';
import inscricoesApi from '../../utils/api/inscricoesApi';
import { displayError } from './notificacoesActions';

export const getInscricaoRequest = createAction('GET_INSCRICAO_REQUEST');
export const getInscricaoSuccess = createAction('GET_INSCRICAO_SUCCESS');
export const getInscricaoError = createAction('GET_INSCRICAO_ERROR');

export const getInscricoesStatsRequest = createAction(
  'GET_INSCRICOES_STATS_REQUEST'
);
export const getInscricoesStatsSuccess = createAction(
  'GET_INSCRICOES_STATS_SUCCESS'
);
export const getInscricoesStatsError = createAction(
  'GET_INSCRICOES_STATS_ERROR'
);

export const fetchSearchInscricoesRequest = createAction(
  'FETCH_SEARCH_INSCRICOES_REQUEST'
);
export const fetchSearchInscricoesSuccess = createAction(
  'FETCH_SEARCH_INSCRICOES_SUCCESS'
);

export const fetchInscricoesRequest = createAction('FETCH_INSCRICOES_REQUEST');
export const fetchInscricoesSuccess = createAction('FETCH_INSCRICOES_SUCCESS');
export const fetchInscricoesError = createAction('FETCH_INSCRICOES_ERROR');

export const fetchInscricoesByAlunoRequest = createAction(
  'FETCH_INSCRICOES_BY_ALUNO_REQUEST'
);
export const fetchInscricoesByAlunoSuccess = createAction(
  'FETCH_INSCRICOES_BY_ALUNO_SUCCESS'
);
export const fetchInscricoesByAlunoError = createAction(
  'FETCH_INSCRICOES_BY_ALUNO_ERROR'
);

export const fetchPdfCertificadoRequest = createAction(
  'FETCH_PDF_CERTIFICADO_REQUEST'
);
export const fetchPdfCertificadoSuccess = createAction(
  'FETCH_PDF_CERTIFICADO_SUCCESS'
);
export const fetchPdfCertificadoError = createAction(
  'FETCH_PDF_CERTIFICADO_ERROR'
);

export const getInscricao = (id) => async (dispatch) => {
  try {
    dispatch(getInscricaoRequest());
    const { data } = await inscricoesApi.getInscricao(id);
    dispatch(getInscricaoSuccess(data));
  } catch (e) {
    dispatch(getInscricaoError(e));
    dispatch(displayError(e));
  }
};

export const fetchInscricoes = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchInscricoesRequest());
    const { data } = await inscricoesApi.getInscricoes(page, pageSize);
    dispatch(fetchInscricoesSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchInscricoesError(e));
    dispatch(displayError(e));
  }
};

export const fetchInscricoesByAluno =
  (alunoId, page, pageSize) => async (dispatch) => {
    try {
      dispatch(fetchInscricoesByAlunoRequest());
      const { data } = await inscricoesApi.getInscricoesByAluno(
        alunoId,
        page,
        pageSize
      );
      dispatch(fetchInscricoesByAlunoSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(fetchInscricoesByAlunoError(e));
      dispatch(displayError(e));
    }
  };

export const fetchPdfCertificado = (inscricaoId) => async (dispatch) => {
  try {
    dispatch(fetchPdfCertificadoRequest());
    const { data } = await inscricoesApi.pdfCertificado(inscricaoId);
    dispatch(fetchPdfCertificadoSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchPdfCertificadoError(e));
    dispatch(displayError(e));
  }
};

export const getInscricoesStats = () => async (dispatch) => {
  try {
    dispatch(getInscricoesStatsRequest());
    const { data } = await inscricoesApi.getInscricoesStats();
    dispatch(getInscricoesStatsSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getInscricoesStatsError(e));
    dispatch(displayError(e));
  }
};

export const fetchSearchInscricoes =
  (page, pageSize, search) => async (dispatch) => {
    try {
      dispatch(fetchSearchInscricoesRequest());
      const { data } = await inscricoesApi.searchInscricoes(
        page,
        pageSize,
        search
      );
      dispatch(fetchSearchInscricoesSuccess(data));
    } catch (e) {
      console.log(e);
      dispatch(displayError(e));
    }
  };
