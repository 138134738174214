import { createAction } from 'redux-actions';
import authApi from '../../utils/api/authApi';

export const loginRequest = createAction('LOGIN_REQUEST');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginError = createAction('LOGIN_ERROR');

export const logoutRequest = createAction('LOGOUT_REQUEST');
export const logoutSuccess = createAction('LOGOUT_SUCCESS');
export const logoutError = createAction('LOGOUT_ERROR');

export const userProfileRead = createAction('USER_PROFILE_READ');

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const { data } = await authApi.login(email, password);
    localStorage.setItem('adminNerdflixUser', JSON.stringify(data));
    dispatch(loginSuccess(data));
  } catch (e) {
    console.log(e);
    // dispatch(loginError(e.response.data.error));
  }
};

export const logout = () => async (dispatch) => {
  try {
    dispatch(logoutRequest());
    localStorage.removeItem('adminNerdflixUser');
    dispatch(logoutSuccess());
  } catch (e) {
    console.log(e);
    dispatch(logoutError());
  }
};

export const getUserProfile = () => async (dispatch) => {
  if (JSON.parse(localStorage.getItem('adminNerdflixUser'))) {
    const { user } = JSON.parse(localStorage.getItem('adminNerdflixUser'));
    if (user) {
      dispatch(userProfileRead(user));
    }
  }
};
