import { get, post } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getInscricao: (id) => get(`${API}/inscricao/${id}`),
  getInscricoes: (page, pageSize) =>
    get(`${API}/inscricoes/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  getInscricoesByAluno: (alunoId, page, pageSize) =>
    get(
      `${API}/inscricoes/by-aluno/${alunoId}/${getUserId()}?page=${page}&pageSize=${pageSize}`
    ),
  pdfCertificado: (id) => get(`${API}/inscricao/certificado/pdf/${id}`),
  getInscricoesStats: () => get(`${API}/inscricoes/stats/${getUserId()}`),
  searchInscricoes: (page, pageSize, searchForm) =>
    post(
      `${API}/inscricoes/search/${getUserId()}?page=${page}&pageSize=${pageSize}`,
      searchForm
    ),
};
