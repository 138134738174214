import React from 'react';
import { Badge } from 'reactstrap';

const BadgeInscricaoColor = {
  inscrito: 'secondary',
  'em execução': 'warning',
  concluído: 'success',
  falhou: 'danger',
};

const BadgeInscricao = ({ status }) => (
  <Badge color={BadgeInscricaoColor[status]}>{status}</Badge>
);

const BadgePagamentoColor = {
  Pendente: 'secondary',
  Aguardando: 'warning',
  Pago: 'success',
  Falhou: 'danger',
  Devolvido: 'primary',
};

const BadgePagamento = ({ status }) => (
  <Badge color={BadgePagamentoColor[status]}>{status}</Badge>
);

const BadgeAlunoColor = {
  ativo: 'success',
  inativo: 'danger',
};

const BadgeAluno = ({ status }) => (
  <Badge color={BadgeAlunoColor[status]}>{status}</Badge>
);

const BadgeCertificadoColor = {
  novo: 'secondary',
  publicado: 'success',
  encerrado: 'danger',
};

const BadgeCertificado = ({ status }) => (
  <Badge color={BadgeCertificadoColor[status]}>{status}</Badge>
);

const BadgeCupomColor = {
  percentual: 'primary',
  fixo: 'secondary',
};

const BadgeCupom = ({ tipo }) => (
  <Badge color={BadgeCupomColor[tipo]}> {tipo} </Badge>
);

export {
  BadgeInscricao,
  BadgePagamento,
  BadgeAluno,
  BadgeCertificado,
  BadgeCupom,
};
