import { get, post, put } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getMensagens: (page, pageSize) =>
    get(`${API}/mensagens/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  getMensagem: (id) => get(`${API}/mensagem/${id}/${getUserId()}`),
  createMensagem: (form) => post(`${API}/mensagem/create/${getUserId()}`, form),
  updateMensagem: (id, form) =>
    put(`${API}/mensagem/${id}/${getUserId()}`, form),
  getMensagensStats: () => get(`${API}/mensagens/stats/${getUserId()}`),
};
