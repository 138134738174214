import { handleActions } from 'redux-actions';
import {
  loginRequest,
  loginSuccess,
  loginError,
  logoutRequest,
  logoutSuccess,
  logoutError,
  userProfileRead,
} from '../actions/authActions';

const defaultState = {
  user: {},
  isLoggedIn: false,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [loginRequest](state) {
      return {
        ...state,
        isLoggedIn: false,
        isFetching: true,
        error: null,
      };
    },
    [loginSuccess](state, { payload }) {
      return {
        ...state,
        user: payload,
        isLoggedIn: true,
        isFetching: false,
        error: null,
      };
    },
    [loginError](state, { payload }) {
      return {
        ...state,
        isLoggedIn: false,
        isFetching: false,
        error: payload,
      };
    },
    [logoutRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [logoutSuccess](state, { payload }) {
      return {
        ...state,
        user: payload,
        isLoggedIn: false,
        isFetching: false,
        error: null,
      };
    },
    [logoutError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [userProfileRead](state, { payload }) {
      return {
        ...state,
        user: payload,
        isLoggedIn: payload ? true : false,
        isFetching: false,
        error: null,
      };
    },
  },
  defaultState
);
