/* eslint-disable */
const validate = (values) => {
  const errors = {};
  if (!values.codigo) {
    errors.codigo = 'O campo Código não pode estar vazio';
  }

  if (!values.tipoSelect) {
    errors.tipoSelect = 'Selecione uma opção, por favor';
  } else if (values.tipoSelect.value == 'percentual') {
    if (values.valor_desconto && values.valor_desconto > 100) {
      errors.valor_desconto = 'O valor não pode exceder 100%';
    }
  }

  if (!values.valor_desconto) {
    errors.valor_desconto = 'O campo Valor não pode estar vazio';
  } else if (values.valor_desconto <= 0) {
    errors.valor_desconto =
      'O valor do desconto não pode ser menor ou igual a zero';
  }

  if (!values.quantidade_aplicavel) {
    errors.quantidade_aplicavel = 'O campo Aplicações não pode estar vazio';
  } else if (values.quantidade_aplicavel <= 0) {
    errors.quantidade_aplicavel =
      'As aplicações não podem ser menores ou iguais a zero';
  }

  if (values.validade) {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    let validUntil = new Date(values.validade);
    if (validUntil < currentDate)
      errors.validade =
        'Não é possível especificar uma data de validade passada';
  }

  return errors;
};

export default validate;
