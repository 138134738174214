import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AlunosList from './components/AlunosList';

const Alunos = () => (
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Alunos</h3>
        <h3 className="page-subhead subhead">
          Exibindo todos os alunos inscritos na plataforma, em ordem alfabética
        </h3>
      </Col>
    </Row>
    <Row>
      <AlunosList />
    </Row>
  </Container>
);

export default Alunos;
