import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  sidebarReducer,
  themeReducer,
  rtlReducer,
  alunosReducer,
  authReducer,
  certificadosReducer,
  configReducer,
  cuponsReducer,
  inscricoesReducer,
  mensagensReducer,
  notificacoesReducer,
  pagamentosReducer,
  usuariosReducer,
} from '@/redux/reducers/index';

const reducer = combineReducers({
  theme: themeReducer,
  sidebar: sidebarReducer,
  rtl: rtlReducer,
  alunos: alunosReducer,
  auth: authReducer,
  certificados: certificadosReducer,
  config: configReducer,
  cupons: cuponsReducer,
  inscricoes: inscricoesReducer,
  mensagens: mensagensReducer,
  notificacoes: notificacoesReducer,
  pagamentos: pagamentosReducer,
  usuarios: usuariosReducer,
});

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
