import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Spinner } from 'reactstrap';
import AccountGroupIcon from 'mdi-react/AccountGroupIcon';
import { fetchAlunos } from '../../../redux/actions/alunosActions';

const AlunosCadastrados = () => {
  const dispatch = useDispatch();
  const totalItems = useSelector((state) => state.alunos.totalItems);
  const isFetching = useSelector((state) => state.alunos.isFetching);

  useEffect(() => {
    dispatch(fetchAlunos(1, 1));
  }, [dispatch]);

  return (
    <Col md={6} xl={4} lg={4} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {totalItems && !isFetching && (
            <div className="mobile-app-widget">
              <div className="mobile-app-widget__top-line mobile-app-widget__top-line--blue">
                <p className="mobile-app-widget__total-stat">{totalItems}</p>
                <AccountGroupIcon className="dashboard__trend-icon" />
              </div>
              <div className="mobile-app-widget__title">
                <h5>Alunos Cadastrados</h5>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default AlunosCadastrados;
