import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card, CardBody, Col } from 'reactstrap';
import { AreaChart, Tooltip, Area, ResponsiveContainer, XAxis } from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { getResumoDiario } from '../../../redux/actions/pagamentosActions';

const data = [
  { name: 'Mon', bch: 70.23 },
  { name: 'Tue', bch: 80.5 },
  { name: 'Wed', bch: 38.45 },
  { name: 'Thu', bch: 89.2 },
  { name: 'Fri', bch: 105.61 },
  { name: 'Sat', bch: 98.6 },
  { name: 'Sun', bch: 115 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload) {
    return (
      <div className="dashboard__total-tooltip">
        <p className="label">{`R$${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
    })
  ),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

const ResumoDiarioPagamentos = () => {
  const dispatch = useDispatch();
  const resumoDiario = useSelector(
    (state) => state.pagamentos.resumoPagamentos
  );
  const isFetching = useSelector((state) => state.pagamentos.isFetching);
  const [activeIndex] = useState(0);
  const [valorTotal, setValorTotal] = useState(0);

  useEffect(() => {
    dispatch(
      getResumoDiario(
        moment().startOf('month').format('YYYY-MM-DD'),
        moment().endOf('month').format('YYYY-MM-DD')
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (resumoDiario) {
      setValorTotal(
        resumoDiario
          .map((item) => item.valorTotal)
          .reduce((total, num) => (total += num), 0)
      );
    }
  }, [resumoDiario]);

  return (
    <Col md={6} xl={4} lg={4} xs={12}>
      <Card>
        <CardBody className="dashboard__card-widget">
          <div className="card__title">
            <h5 className="bold-text">Faturamento Mensal</h5>
            <h5 className="subhead">Resumo diário</h5>
          </div>
          <div className="dashboard__total dashboard__total--area">
            <TrendingUpIcon className="dashboard__trend-icon" />
            <p className="dashboard__total-stat">R${valorTotal}</p>
            <div className="dashboard__chart-container">
              <ResponsiveContainer height={70}>
                <AreaChart
                  data={resumoDiario}
                  margin={{ top: 0, left: 0, bottom: 0 }}
                >
                  <Tooltip content={<CustomTooltip />} />
                  <XAxis hide />
                  <Area
                    name="BCH"
                    type="monotone"
                    dataKey="valorTotal"
                    fill="#6faae1"
                    stroke="#6faae1"
                    fillOpacity={0.2}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ResumoDiarioPagamentos;
