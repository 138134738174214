import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Row,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import FormField from '@/shared/components/form/FormField';
import renderSelectField from '@/shared/components/form/Select';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import validate from './validate';
import {
  getCertificado,
  createCertificado,
  updateCertificado,
  newCertificado,
} from '../../../redux/actions/certificadosActions';
import { fetchUsuarios } from '../../../redux/actions/usuariosActions';

const CertificadoForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const certificado = useSelector((state) => state.certificados.certificado);
  const usuarios = useSelector((state) => state.usuarios.usuarios);
  const isFetching = useSelector((state) => state.certificados.isFetching);
  const error = useSelector((state) => state.certificados.error);
  const { id } = props.match.params;
  const isAddMode = !id;

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getCertificado(id));
    } else {
      dispatch(newCertificado());
    }
    dispatch(fetchUsuarios());
  }, [dispatch]);

  const onSubmit = (data) => {
    data.status = data.statusSelect.value;
    data.professor = data.professorSelect.value;
    isAddMode
      ? dispatch(createCertificado(data))
      : dispatch(updateCertificado(id, data));
    setIsRedirect(true);
  };

  const modeloVF = {
    numero: certificado.teste ? certificado.teste.length + 1 : 1,
    descricao: '',
    respostas: [
      {
        numero: 1,
        descricao: 'Verdadeiro',
      },
      {
        numero: 2,
        descricao: 'Falso',
      },
    ],
    resposta_correta: '',
  };

  if (certificado) {
    certificado.statusSelect = {
      value: certificado.status,
      label: certificado.status,
    };
    certificado.professorSelect = {
      value: certificado.professor?._id,
      label: certificado.professor?.name,
    };
  }

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/certificados" />;
  }

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{`${
              isAddMode ? 'Nova' : 'Editar'
            } Certificado`}</h5>
          </div>

          {isFetching && <Spinner className="table-fetch-spinner" />}
          {(certificado || isAddMode) && !isFetching && (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={certificado}
              mutators={{
                ...arrayMutators,
              }}
            >
              {({
                handleSubmit,
                form: {
                  reset,
                  pristine,
                  submitting,
                  mutators: { push, pop },
                },
              }) => (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col lg={5}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Título</span>
                      <div className="form__form-group-field">
                        <Field
                          name="titulo"
                          type="text"
                          component={FormField}
                          isAboveError={isAboveError}
                          placeholder="Título do certificado"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Descrição</span>
                      <div className="form__form-group-field">
                        <Field
                          name="descricao"
                          type="textarea"
                          component="textarea"
                          placeholder="Descrição detalhada do certificado, instruções e etc..."
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Conteúdo Programático
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="conteudo_programa"
                          type="textarea"
                          component="textarea"
                          placeholder="Detalhes do conteúdo programático...."
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span classNa me="form__form-group-label">
                        Professor
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="professorSelect"
                          component={renderSelectField}
                          type="text"
                          options={usuarios.map((usuario) => ({
                            value: usuario._id,
                            label: usuario.name,
                          }))}
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Tempo de Prova (em minutos)
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="tempo_prova"
                          type="number"
                          component={FormField}
                          placeholder="30"
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Nota para Aprovação (em %)
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="nota_aprovacao"
                          type="number"
                          component={FormField}
                          placeholder="Certificado enviada ao cliente quando...."
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Carga Horária
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="carga_horaria"
                          type="number"
                          component={FormField}
                          placeholder="10"
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Status</span>
                      <div className="form__form-group-field">
                        <Field
                          name="statusSelect"
                          component={renderSelectField}
                          type="text"
                          options={[
                            { value: 'novo', label: 'Novo' },
                            { value: 'publicado', label: 'Publicado' },
                            { value: 'encerrado', label: 'Encerrado' },
                          ]}
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Tentativas</span>
                      <div className="form__form-group-field">
                        <Field
                          name="tentativas"
                          type="number"
                          component={FormField}
                          placeholder="0"
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Valor (R$)</span>
                      <div className="form__form-group-field">
                        <Field
                          name="valor"
                          type="number"
                          component={FormField}
                          placeholder="19,90"
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={7}>
                    <Row>
                      <ButtonToolbar>
                        <Button
                          size="sm"
                          onClick={() => {
                            push('teste', modeloVF);
                          }}
                        >
                          Add questão V/F
                        </Button>
                      </ButtonToolbar>
                    </Row>
                    <FieldArray name="teste">
                      {({ fields }) => (
                        <Row>
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              Perguntas ({fields.length})
                            </span>
                          </div>
                          {fields.map((pergunta, indexPergunta) => (
                            <div
                              className="form__form-group"
                              key={`pergunta-${indexPergunta}`}
                            >
                              <div className="form__form-group-field">
                                <Field
                                  name={`${pergunta}.descricao`}
                                  type="text"
                                  component={FormField}
                                  placeholder="Texto da pergunta..."
                                  isAboveError={isAboveError}
                                />
                              </div>
                              <div className="form__form-group">
                                <span className="form__form-group-label">
                                  Respostas
                                </span>
                                <FieldArray name={`${pergunta}.respostas`}>
                                  {({ fields }) => {
                                    return fields.map(
                                      (resposta, indexResposta) => (
                                        <div
                                          className="form__form-group-field"
                                          key={`resposta-${indexResposta}`}
                                        >
                                          <Field
                                            name={`${pergunta}.resposta_correta`}
                                            component={renderRadioButtonField}
                                            radioValue={indexResposta + 1}
                                            initialValue={`${pergunta}.resposta_correta`}
                                            isAboveError={isAboveError}
                                          />
                                          <Field
                                            name={`${resposta}.descricao`}
                                            component={FormField}
                                            isAboveError={isAboveError}
                                            disabled
                                          />
                                          <Field
                                            name={`${resposta}.numero`}
                                            component={FormField}
                                            type="hidden"
                                            isAboveError={isAboveError}
                                          />
                                        </div>
                                      )
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            </div>
                          ))}
                        </Row>
                      )}
                    </FieldArray>
                  </Col>
                  <Col lg={12}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit">
                        Salvar
                      </Button>
                      <Button type="button" onClick={() => setIsRedirect(true)}>
                        Cancelar
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </form>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

CertificadoForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

CertificadoForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default CertificadoForm;
