import { createAction } from 'redux-actions';
import mensagensApi from '../../utils/api/mensagensApi';
import { displayError, displaySuccess } from './notificacoesActions';

export const fetchMensagensRequest = createAction('FETCH_MENSAGENS_REQUEST');
export const fetchMensagensSuccess = createAction('FETCH_MENSAGENS_SUCCESS');
export const fetchMensagensError = createAction('FETCH_MENSAGENS_ERROR');

export const getMensagemRequest = createAction('GET_MENSAGEM_REQUEST');
export const getMensagemSuccess = createAction('GET_MENSAGEM_SUCCESS');
export const getMensagemError = createAction('GET_MENSAGEM_ERROR');

export const createMensagemRequest = createAction('CREATE_MENSAGEM_REQUEST');
export const createMensagemSuccess = createAction('CREATE_MENSAGEM_SUCCESS');
export const createMensagemError = createAction('CREATE_MENSAGEM_ERROR');

export const updateMensagemRequest = createAction('UPDATE_MENSAGEM_REQUEST');
export const updateMensagemSuccess = createAction('UPDATE_MENSAGEM_SUCCESS');
export const updateMensagemError = createAction('UPDATE_MENSAGEM_ERROR');

export const newMensagemRequest = createAction('NEW_MENSAGEM_REQUEST');

export const fetchMensagensStatsRequest = createAction(
  'FETCH_MENSAGENS_STATS_REQUEST'
);
export const fetchMensagensStatsSuccess = createAction(
  'FETCH_MENSAGENS_STATS_SUCCESS'
);
export const fetchMensagensStatsError = createAction(
  'FETCH_MENSAGENS_STATS_ERROR'
);

export const fetchMensagens = (page, pageSize) => async (dispatch) => {
  try {
    dispatch(fetchMensagensRequest());
    const { data } = await mensagensApi.getMensagens(page, pageSize);
    dispatch(fetchMensagensSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(fetchMensagensError());
    dispatch(displayError(e));
  }
};

export const createMensagem = (form) => async (dispatch) => {
  try {
    dispatch(createMensagemRequest());
    const { data } = await mensagensApi.createMensagem(form);
    dispatch(createMensagemSuccess(data));
    dispatch(displaySuccess('Mensagem incluída com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(createMensagemError(e));
    dispatch(displayError(e));
  }
};

export const getMensagem = (id) => async (dispatch) => {
  try {
    dispatch(getMensagemRequest());
    const { data } = await mensagensApi.getMensagem(id);
    dispatch(getMensagemSuccess(data));
  } catch (e) {
    console.log(e);
    dispatch(getMensagemError(e));
    dispatch(displayError(e));
  }
};

export const updateMensagem = (id, form) => async (dispatch) => {
  try {
    dispatch(updateMensagemRequest());
    const { data } = await mensagensApi.updateMensagem(id, form);
    dispatch(updateMensagemSuccess(data));
    dispatch(displaySuccess('Mensagem atualizada com sucesso!'));
  } catch (e) {
    console.log(e);
    dispatch(updateMensagemError(e));
    dispatch(displayError(e));
  }
};

export const newMensagem = () => async (dispatch) => {
  dispatch(newMensagemRequest());
};

export const fetchMensagensStats = () => async (dispatch) => {
  try {
    dispatch(fetchMensagensStatsRequest());
    const { data } = await mensagensApi.getMensagensStats();
    dispatch(fetchMensagensStatsSuccess(data));
  } catch (e) {
    dispatch(fetchMensagensStatsError());
    dispatch(displayError(e));
  }
};
