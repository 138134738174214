import { handleActions } from 'redux-actions';
import {
  getConfigRequest,
  getConfigSuccess,
  getConfigError,
  updateConfigRequest,
  updateConfigSuccess,
  updateConfigError,
} from '../actions/configActions';

const defaultState = {
  config: {},
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [getConfigRequest](state) {
      return {
        ...state,
        config: {},
        isFetching: true,
        error: null,
      };
    },
    [getConfigSuccess](state, { payload }) {
      return {
        ...state,
        config: payload,
        isFetching: false,
        error: null,
      };
    },
    [getConfigError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [updateConfigRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [updateConfigSuccess](state, { payload }) {
      return {
        ...state,
        config: payload,
        isFetching: false,
        error: null,
      };
    },
    [updateConfigError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
