import { get, post, put } from './base/index';
import { API, getUserId } from '../helpers';

export default {
  getCertificados: (page, pageSize) =>
    get(`${API}/certificados/${getUserId()}?page=${page}&pageSize=${pageSize}`),
  getCertificado: (id) => get(`${API}/certificado/admin/${id}/${getUserId()}`),
  createCertificado: (form) =>
    post(`${API}/certificado/create/${getUserId()}`, form),
  updateCertificado: (id, form) =>
    put(`${API}/certificado/${id}/${getUserId()}`, form),
};
