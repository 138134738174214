import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Spinner, Button } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/pt-br';
import EyeIcon from 'mdi-react/EyeIcon';
import { Link } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import CertificadoSelect from '../../Comuns/CertificadoSelect';
import renderSelectField from '@/shared/components/form/Select';
import renderIntervalDatePickerField from '@/shared/components/form/IntervalDatePicker';
import ReactTableBase from '../../../shared/components/table/ReactTableBase';
import { fetchSearchPagamentos } from '../../../redux/actions/pagamentosActions';
import { BadgePagamento } from '../../../shared/components/Badges';
import {
  listaSituacaoPagamento,
  imagensPagamento,
  listaTipoPagamento,
} from '@/utils/helpers';
import validate from './validate';

const PagamentosList = () => {
  const dispatch = useDispatch();
  const pagamentos = useSelector((state) => state.pagamentos.pagamentos);
  const totalPages = useSelector((state) => state.pagamentos.totalPages);
  const totalItems = useSelector((state) => state.pagamentos.totalItems);
  const isFetching = useSelector((state) => state.pagamentos.isFetching);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState({});
  moment.locale('pt-br');

  const colunas = useMemo(() => [
    {
      Header: 'ALUNO',
      accessor: 'aluno_name',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'CERTIFICADO',
      accessor: 'certificado_titulo',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'DATA',
      accessor: 'dataPagamento',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'VALOR',
      accessor: 'valor',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'FORMA DE PAGAMENTO',
      accessor: 'forma_pagamento',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      disableGlobalFilter: true,
      width: 80,
    },
    {
      Header: 'AÇÃO',
      accessor: 'acao',
      disableGlobalFilter: true,
      width: 65,
    },
  ]);

  const dadosTabela = (pagamentos) => {
    return pagamentos.map((pagamento) => ({
      aluno_name: pagamento.aluno?.name,
      certificado_titulo: pagamento.certificado?.titulo,
      dataPagamento: moment(pagamento.createdAt).format('DD/MM/YYYY LT'),
      valor: `R$${pagamento.valor.toFixed(2).replace('.', ',')}`,
      forma_pagamento: (
        <img
          className="payment__credit-card_img"
          src={imagensPagamento[pagamento.forma_pagamento]}
          alt="visa"
        />
      ),
      status: <BadgePagamento status={pagamento.status} />,
      acao: (
        <Link
          color="primary"
          className="btn btn-outline-secondary btn-sm list__table-btn-action mb-0 px-2"
          to={`/pagamento/edit/${pagamento._id}`}
        >
          <EyeIcon /> Ver
        </Link>
      ),
    }));
  };

  useEffect(() => {
    dispatch(fetchSearchPagamentos(parseInt(page), pageSize, search));
  }, [dispatch, page, pageSize, search]);

  if (totalPages > 0 && totalPages < page) {
    setPage(totalPages);
  }

  const onSubmit = (data) => {
    const searchForm = {};
    searchForm.search = data.search;
    searchForm.filters = {
      status: data.statusSelect?.value,
      certificado: data.certificadoSelect?.value,
      forma_pagamento: data.pagamentoSelect?.value,
    };
    if (data?.createdAt) {
      data.createdAt.start && data.createdAt.start.setHours(0, 0, 0, 0);
      data.createdAt.end && data.createdAt.end.setHours(23, 59, 59, 99);
    }
    searchForm.dateFilters = {
      createdAt: data.createdAt,
    };
    setSearch(searchForm);
  };

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Pagamentos realizados</h5>
          </div>
          <div className="search__form">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit, form: { submitting } }) => (
                <form
                  className={`form form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col xl={4}>
                    <CertificadoSelect name="certificadoSelect" />
                  </Col>
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="pagamentoSelect"
                          component={renderSelectField}
                          type="text"
                          options={[].concat(
                            { value: undefined, label: 'Todos' },
                            listaTipoPagamento
                          )}
                          placeholder="Tipo de Pagamento"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={4}>
                    <div className="form__form-group">
                      <div className="form__form-group-field">
                        <Field
                          name="statusSelect"
                          component={renderSelectField}
                          type="text"
                          options={[].concat(
                            { value: undefined, label: 'Todos' },
                            listaSituacaoPagamento
                          )}
                          placeholder="Situação"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={6}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Data do Pagamento
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="createdAt"
                          component={renderIntervalDatePickerField}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xl={6} className="align-self-end text-end">
                    <Button color="primary" type="submit">
                      Pesquisar
                    </Button>
                  </Col>
                </form>
              )}
            </Form>
          </div>
          {isFetching && <Spinner className="table-fetch-spinner" />}
          {pagamentos && !isFetching && (
            <ReactTableBase
              key={'common'}
              columns={colunas}
              data={dadosTabela(pagamentos)}
              tableConfig={{
                isEditable: false,
                isResizable: false,
                isSortable: false,
                withDragAndDrop: false,
                withPagination: true,
                withSearchEngine: false,
                manualPageSize: [10, 20, 30, 40],
                placeholder: 'Search by First name...',
              }}
              setPage={setPage}
              setPageSize={setPageSize}
              currentpage={page}
              pageSize={pageSize}
              totalPages={totalPages}
              totalItems={totalItems}
            />
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PagamentosList;
