import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import { Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import moment from 'moment';
import 'moment/locale/pt-br';
import { getInscricoesStats } from '../../../redux/actions/inscricoesActions';
import getTooltipStyles from '@/shared/helpers';
import Panel from '@/shared/components/Panel';

const style = {
  left: 0,
  width: 150,
  lineHeight: '24px',
  position: 'relative',
};

const corStatus = {
  inscrito: '#787985',
  'em execução': '#ffc107',
  concluído: '#27b86f',
  falhou: '#ed0b0b',
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__booking-reservations-chart-legend dashboard__chart-legend">
    {payload.map((entry) => (
      <li key={entry.value}>
        <span style={{ backgroundColor: corStatus[entry.value] }} />
        <p>{entry.value}</p>
      </li>
    ))}
  </ul>
);

const ResumoInscricoes = ({ dir, themeName }) => {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.inscricoes.stats);
  const isFetching = useSelector((state) => state.inscricoes.isFetching);
  const [valorTotal, setValorTotal] = useState(0);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [refresh, setRefresh] = useState(true);
  moment.locale('pt-br');

  useEffect(() => {
    if (refresh) {
      dispatch(getInscricoesStats());
    }
  }, [dispatch, refresh]);

  useEffect(() => {
    if (stats) {
      setValorTotal(
        stats
          .map((item) => item.count)
          .reduce((total, num) => (total += num), 0)
      );
    }
  }, [stats]);

  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({
        x: dir === 'ltr' ? e.tooltipPosition.x - 130 : e.tooltipPosition.x / 10,
        y: e.tooltipPosition.y - 40,
      });
    }
  };

  const dadosResumoInscricoes = stats.map((resultado) => ({
    name: resultado._id,
    value: resultado.count,
    fill: corStatus[resultado._id],
  }));

  return (
    <Panel
      lg={6}
      xl={4}
      md={12}
      title={'Inscrições'}
      subhead={`Atualizado em ${moment().format('l LT')}`}
      refresh={refresh}
      setRefresh={setRefresh}
    >
      {isFetching && <Spinner className="table-fetch-spinner" />}
      {stats && !isFetching && (
        <div className="dashboard__visitors-chart">
          <p className="dashboard__visitors-chart-title">
            Incrições realizadas
          </p>
          <p className="dashboard__visitors-chart-number">{valorTotal}</p>
          <ResponsiveContainer
            className="dashboard__chart-pie"
            width="100%"
            height={220}
          >
            <PieChart className="dashboard__chart-pie-container">
              <Tooltip
                position={coordinates}
                {...getTooltipStyles(themeName)}
              />
              <Pie
                data={dadosResumoInscricoes}
                dataKey="value"
                cy={110}
                innerRadius={70}
                outerRadius={100}
                onMouseMove={onMouseMove}
              />
              <Legend
                layout="vertical"
                verticalAlign="bottom"
                wrapperStyle={style}
                content={renderLegend}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
      )}
    </Panel>
  );
};

export default ResumoInscricoes;
