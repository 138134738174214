import { handleActions } from 'redux-actions';
import {
  fetchUsuariosRequest,
  fetchUsuariosSuccess,
  fetchUsuariosError,
} from '../actions/usuariosActions';

const defaultState = {
  usuarios: [],
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchUsuariosRequest](state) {
      return {
        ...state,
        usuarios: [],
        isFetching: true,
        error: null,
      };
    },
    [fetchUsuariosSuccess](state, { payload }) {
      return {
        ...state,
        usuarios: payload,
        isFetching: false,
        error: null,
      };
    },
    [fetchUsuariosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
