import { handleActions } from 'redux-actions';
import {
  fetchCertificadosRequest,
  fetchCertificadosSuccess,
  fetchCertificadosError,
  getCertificadoRequest,
  getCertificadoSuccess,
  getCertificadoError,
  createCertificadoRequest,
  createCertificadoSuccess,
  createCertificadoError,
  updateCertificadoRequest,
  updateCertificadoSuccess,
  updateCertificadoError,
  newCertificadoRequest,
  fetchCertificadosStatsRequest,
  fetchCertificadosStatsSuccess,
  fetchCertificadosStatsError,
} from '../actions/certificadosActions';

const defaultState = {
  certificados: [],
  totalPages: 0,
  certificado: {},
  count: 0,
  isFetching: false,
  error: null,
};

export default handleActions(
  {
    [fetchCertificadosRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCertificadosSuccess](state, { payload }) {
      return {
        ...state,
        certificados: payload.docs,
        totalItems: payload.totalDocs,
        totalPages: payload.totalPages,
        isFetching: false,
        error: null,
      };
    },
    [fetchCertificadosError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [getCertificadoRequest](state) {
      return {
        ...state,
        certificado: {},
        isFetching: true,
        error: null,
      };
    },
    [getCertificadoSuccess](state, { payload }) {
      return {
        ...state,
        certificado: payload,
        isFetching: false,
        error: null,
      };
    },
    [getCertificadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [createCertificadoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [createCertificadoSuccess](state, { payload }) {
      return {
        ...state,
        certificado: payload,
        isFetching: false,
        error: null,
      };
    },
    [createCertificadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [updateCertificadoRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [updateCertificadoSuccess](state, { payload }) {
      return {
        ...state,
        certificado: payload,
        isFetching: false,
        error: null,
      };
    },
    [updateCertificadoError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
    [newCertificadoRequest](state, { payload }) {
      return {
        ...state,
        certificado: {},
        isFetching: false,
        error: payload,
      };
    },
    [fetchCertificadosStatsRequest](state) {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    },
    [fetchCertificadosStatsSuccess](state, { payload }) {
      return {
        ...state,
        count: payload.countCertificados,
        isFetching: false,
        error: null,
      };
    },
    [fetchCertificadosStatsError](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    },
  },
  defaultState
);
