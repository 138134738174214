import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Spinner,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import FormField from '@/shared/components/form/FormField';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import renderRadioButtonField from '@/shared/components/form/RadioButton';
import validate from './validate';
import {
  getMensagem,
  createMensagem,
  updateMensagem,
  newMensagem,
} from '../../../redux/actions/mensagensActions';

const renderTextEditor = ({ input }) => <TextEditor {...input} />;

const MensagemForm = ({ isHorizontal, isAboveError, props }) => {
  const dispatch = useDispatch();
  const [isRedirect, setIsRedirect] = useState(false);
  const mensagem = useSelector((state) => state.mensagens.mensagem);
  const isFetching = useSelector((state) => state.mensagens.isFetching);
  const error = useSelector((state) => state.mensagens.error);
  const { id } = props.match.params;
  const isAddMode = !id;

  useEffect(() => {
    if (!isAddMode) {
      dispatch(getMensagem(id));
    } else {
      dispatch(newMensagem());
    }
  }, [dispatch]);

  const onSubmit = (data) => {
    isAddMode
      ? dispatch(createMensagem(data))
      : dispatch(updateMensagem(id, data));
    setIsRedirect(true);
  };

  if (isRedirect && !isFetching && !error) {
    return <Redirect to="/mensagens" />;
  }

  return (
    <Col md={6} lg={12} xl={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{`${
              isAddMode ? 'Nova' : 'Editar'
            } Mensagem`}</h5>
          </div>

          {isFetching && <Spinner className="table-fetch-spinner" />}
          {(mensagem || isAddMode) && !isFetching && (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={mensagem}
            >
              {({ handleSubmit, form: { reset, pristine, submitting } }) => (
                <form
                  className={`form ${isHorizontal && 'form--horizontal'}`}
                  onSubmit={handleSubmit}
                >
                  <Col lg={5}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Código</span>
                      <div className="form__form-group-field">
                        <Field
                          name="codigo"
                          type="text"
                          component={FormField}
                          isAboveError={isAboveError}
                          placeholder="Código da mensagem"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Título</span>
                      <div className="form__form-group-field">
                        <Field
                          name="titulo"
                          type="text"
                          component={FormField}
                          isAboveError={isAboveError}
                          placeholder="Aprovação no Teste, Pedido Recebido..."
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Descrição</span>
                      <div className="form__form-group-field">
                        <Field
                          name="descricao"
                          type="text"
                          component={FormField}
                          placeholder="Mensagem enviada ao cliente quando...."
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Ativo</span>
                      <div className="form__form-group-field">
                        <Field
                          name="ativo"
                          type="checkbox"
                          component={renderCheckBoxField}
                          label="Ativo"
                          initialValue={mensagem.ativo}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Canal de envio
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="canal"
                          component={renderRadioButtonField}
                          label="Email"
                          radioValue="email"
                          initialValue="canal"
                          isAboveError={isAboveError}
                        />
                      </div>
                      <div className="form__form-group-field">
                        <Field
                          name="canal"
                          component={renderRadioButtonField}
                          label="Whatsapp"
                          radioValue="whatsapp"
                          initialValue="canal"
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Assunto</span>
                      <div className="form__form-group-field">
                        <Field
                          name="assunto"
                          type="text"
                          component={FormField}
                          placeholder="Campo assunto do email"
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Corpo da mensagem
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="corpo"
                          value="corpo"
                          component={renderTextEditor}
                          isAboveError={isAboveError}
                        />
                      </div>
                    </div>{' '}
                  </Col>
                  <Col lg={12}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" type="submit">
                        Salvar
                      </Button>
                      <Button type="button" onClick={() => setIsRedirect(true)}>
                        Cancelar
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </form>
              )}
            </Form>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

MensagemForm.propTypes = {
  isHorizontal: PropTypes.bool,
  isAboveError: PropTypes.bool,
};

MensagemForm.defaultProps = {
  isHorizontal: false,
  isAboveError: false,
};

export default MensagemForm;
